import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

function Admin(props) {
  const location = useLocation();
  useEffect(() => {
    if (
      !sessionStorage.getItem('adminToken') &&
      location.pathname !== '/admin/login'
    ) {
      window.location.href = '/admin/login';
    } else if (
      sessionStorage.getItem('adminToken') &&
      (location.pathname === '/admin/login' || location.pathname === '/admin')
    ) {
      window.location.href = '/admin/dashboard';
    }

    if (location.pathname.includes('admin')) {
      document.getElementById('root').style.cssText = `max-width:unset;`;
    } else {
      document.getElementById('root').style.cssText = ``;
    }

    return () => {
      document.getElementById('root').style.cssText = '';
    };
  }, [location]);
  return <Outlet />;
}

export default Admin;
