import React from 'react';
import { RowDiv, Div, Span } from 'styles/CommonCSS';
import Text from 'components/Element/Text';
import Button from 'components/Element/Button';
import moment from 'moment-timezone';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import Detail from 'Layout/Detail';
import Input from 'components/Input/Input';
import InputFile from 'components/Input/InputFile';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Writer from 'components/Editor/Writer';
import CheckBox from 'components/Input/CheckBox';
import styled from 'styled-components';

function NoticeForm({
  values,
  onChange,
  type,
  navigate,
  submit,
  del,
  fileUpload,
  imagePath,
  checkBoxChange,
}) {
  return (
    <Div padding="20px 40px 0" maxWidth={1060}>
      <Text mt={10} size={20} weight={700}>
        공지사항 {type === 'add' ? '등록' : '상세'}
      </Text>
      <RowDiv mt={20} mb={6} justify="space-between">
        <Text size={14} weight={700}>
          제목
        </Text>
        {type !== 'add' && (
          <RowDiv gap={20}>
            <RowDiv gap={4}>
              <Text size={14}>등록일</Text>
              <Text size={14}>
                {moment(values?.created_dt).format('YYYY-MM-DD')}
              </Text>
            </RowDiv>
            <Span color="colorCF">|</Span>
            <RowDiv gap={4}>
              <Text size={14}>조회수</Text>
              <Text size={14}>{values?.views}</Text>
            </RowDiv>
          </RowDiv>
        )}
      </RowDiv>
      <RowDiv gap={21}>
        <Input
          width="100%"
          placeholder="제목을 입력하세요"
          name="title"
          value={values?.title}
          onChange={onChange}
        />
        <RowDiv align="center">
          <CheckBox
            id="isFixed"
            name="isFixed"
            checked={values?.isFixed === '1'}
            value={values?.isFixed}
            onChange={checkBoxChange}
          />
          <Label htmlFor="isFixed">
            <Text weight={700}></Text>고정
          </Label>
        </RowDiv>
      </RowDiv>
      <Div mt={5} mb={7}>
        <Writer
          onChange={onChange}
          name="content"
          value={values?.content}
          imagePath="admin/notice/upload/editor/file"
        />
      </Div>
      <Div>
        <Text size={14} weight={700} mb={4}>
          파일첨부
        </Text>
        <InputFile
          width="100%"
          placeholder="해당 파일을 첨부하세요"
          name={type === 'add' ? 'fileUrl' : 'attachment_file_name'}
          fileUpload={fileUpload}
          value={values?.fileName || values?.attachment_file_name}
        />
      </Div>
      <RowDiv justify="space-between" mt={20}>
        <Button
          text="목록"
          bgColor="white"
          border="main"
          color="main"
          height={40}
          onClick={() => {
            if (type === 'add') {
              window.location.reload();
            } else {
              navigate(-1);
            }
          }}
        />
        <RowDiv gap={10}>
          <Button
            text={type !== 'add' ? '삭제' : '취소'}
            onClick={() =>
              type !== 'add' ? del(values.id) : window.location.reload()
            }
            height={40}
          />

          <Button
            text={type !== 'add' ? '수정' : '등록'}
            onClick={() => (type !== 'add' ? submit('modify') : submit('add'))}
            height={40}
            active
          />
        </RowDiv>
      </RowDiv>
    </Div>
  );
}

const Label = styled.label`
  cursor: pointer;
`;

export default NoticeForm;
