import React from 'react';
import { ReactComponent as LeafRIcon } from 'assets/icon/leaf-r.svg';
import { ReactComponent as LeafLIcon } from 'assets/icon/leaf-l.svg';
import styled, { css } from 'styled-components';
import Text from 'components/Element/Text';
import { Div, ColDiv } from 'styles/CommonCSS';

function RankingDiv({ ranking, nickName, point, type, item, ...props }) {
  return (
    <ColDiv position="relative" justify="center">
      <Div>
        <LeafL type={type} />
        <User src={item?.thumbnail} type={type} />
        <LeafR type={type} />
      </Div>
      <RankDiv
        width={50}
        height={22}
        bgColor={
          type === 'second'
            ? '#B1D2DA'
            : type === 'third'
            ? '#FF9E66'
            : '#FFCD40'
        }
        radius={20}
        align="center"
        justify="center"
        type={type}
      >
        <Text size={14} weight={700} color="white">
          {item?.rank} 위
        </Text>
      </RankDiv>
    </ColDiv>
  );
}

const User = styled.img`
  width: ${({ type }) => (type === 'first' ? '90px' : '70px')};
  height: ${({ type }) => (type === 'first' ? '90px' : '70px')};
`;

const LeafR = styled(LeafRIcon)`
  position: absolute;
  right: -16px;
  bottom: -3px;

  ${({ type }) =>
    type === 'second'
      ? css`
          width: 35px;
          height: 64px;
          right: -14px;
          bottom: 0px;
          & > path:nth-child(1) {
            fill: #d6e4e8;
          }
          & > path:nth-child(2) {
            fill: #b1d2da;
          }
        `
      : type === 'third'
      ? css`
          right: -14px;
          bottom: 0px;
          width: 35px;
          height: 64px;
          & > path:nth-child(1) {
            fill: #ffc691;
          }
          & > path:nth-child(2) {
            fill: #ff9e66;
          }
        `
      : ''}
`;
const LeafL = styled(LeafLIcon)`
  position: absolute;
  left: -16px;
  bottom: -3px;
  ${({ type }) =>
    type === 'second'
      ? css`
          left: -14px;
          bottom: 0px;
          width: 35px;
          height: 64px;
          & > g > path:nth-child(1) {
            fill: #d6e4e8;
          }
          & > g > path:nth-child(2) {
            fill: #b1d2da;
          }
        `
      : type === 'third'
      ? css`
          left: -14px;
          bottom: 0px;
          width: 35px;
          height: 64px;
          & > g > path:nth-child(1) {
            fill: #ffc691;
          }
          & > g > path:nth-child(2) {
            fill: #ff9e66;
          }
        `
      : ''}
`;

const RankDiv = styled(ColDiv)`
  position: absolute;
  bottom: ${({ type }) => (type === 'first' ? '-10px' : '-10px')};
  left: 50%;
  transform: translateX(-50%);
`;

export default RankingDiv;
