import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ColDiv, Div, RowDiv } from 'styles/CommonCSS';
import hexToRGB from 'modules/hexToRGB';
import { ReactComponent as LogoIcon } from 'assets/icon/admin-logo.svg';
import Text from 'components/Element/Text';
import { useNavigate } from 'react-router-dom';

function Admin({ children }) {
  useEffect(() => {}, []);

  const navigate = useNavigate();

  return (
    <RowDiv>
      <Navi width={220} height="100vh" bgColor="white">
        <Div padding="60px 0 72px 20px">
          <LogoIcon />
        </Div>
        <ColDiv
          height={46}
          justify="center"
          pl={30}
          pointer
          onClick={() => navigate('/admin/dashboard')}
        >
          <Line selected={window.location.pathname === '/admin/dashboard'} />
          <Txt
            size={14}
            weight={700}
            selected={window.location.pathname === '/admin/dashboard'}
          >
            대시보드
          </Txt>
        </ColDiv>
        <ColDiv
          height={46}
          justify="center"
          pl={30}
          pointer
          onClick={() => navigate('/admin/challenge')}
        >
          <Line selected={window.location.pathname === '/admin/challenge'} />
          <Txt
            size={14}
            weight={700}
            selected={window.location.pathname === '/admin/challenge'}
          >
            챌린지 관리
          </Txt>
        </ColDiv>
        <ColDiv
          height={46}
          justify="center"
          pl={30}
          pointer
          onClick={() => navigate('/admin/notice')}
        >
          <Line selected={window.location.pathname.includes('notice')} />
          <Txt
            size={14}
            weight={700}
            selected={window.location.pathname.includes('notice')}
          >
            공지사항
          </Txt>
        </ColDiv>
      </Navi>
      <Div bgColor="#FBFBFB" width="calc(100% - 220px)">
        {children}
      </Div>
    </RowDiv>
  );
}

const Navi = styled(Div)`
  box-shadow: 0px 6px 10px ${hexToRGB('#464646', 0.06)};
`;

const Line = styled(Div)`
  width: 4px;
  height: 46px;
  position: absolute;
  left: 0;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.main : 'unset'};
`;

const Txt = styled(Text)`
  color: ${({ selected, theme }) => (selected ? theme.colors.main : 'inherit')};
`;

export default Admin;
