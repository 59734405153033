import React, { useState, useEffect } from 'react';
import { adminApi } from 'api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from 'components/Element/Loading';
import Admin from 'Layout/Admin';
import NoticeForm from 'components/Routes/Admin/NoticeForm';
import AdminAlert from 'components/Element/Modal/AdminAlert';
import NoticeListPresenter from './NoticeListPresenter';

function NoticeListContainer(props) {
  const adminToken = sessionStorage.getItem('adminToken');
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  const [step, setStep] = useState();

  const [filters, setFilters] = useState({
    perPage: 10,
    page: 1,
    lastRowNum: 0,
    keyword: '',
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
  });

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.noticeApi.noticeList(filters);
    if (info) {
      const { result } = info;
      setPages({
        currentCount: result.currentCount,
        currentPage: result.currentPage,
        perPage: result.perPage,
        totalPages: result.totalPages,
        totalCount: result.totalCount,
      });
      setList(result.list);
      setLoading(false);
    }
  };

  useEffect(() => {
    setValues({ ...values, isFixed: '0' });
    getList();
    return () => setLoading(true);
  }, [filters]);

  const goDetail = (id) => {
    if (id) {
      navigate(`${String(id)}`, { state: { id } });
    }
  };

  const goAdd = () => {
    setStep('add');
  };

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  const checkBoxChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      setValues({ ...values, [name]: '1' });
    } else {
      setValues({ ...values, [name]: '0' });
    }
  };

  // 등록

  const submitMsg = {
    open: true,
    text: '작성하신 내용으로 등록하시겠습니까?',
    cancel: true,
    onClick: () => submitForm(),
  };

  const doneMsg = {
    open: true,
    text: '전송이 완료되었습니다.',
    onClick: () => {
      window.location.reload();
    },
  };

  const errerMsg = {
    open: true,
    text: '오류가 발생했습니다\n다시 시도해 주세요.',
  };

  const submit = () => {
    setModal(submitMsg);
  };

  const submitForm = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.noticeApi.addNotice(values);
    if (info) {
      setModal(doneMsg);
    } else {
      setModal(errerMsg);
    }
  };

  // 이미지 업로드

  const fileUpload = (event) => {
    event.preventDefault();
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      reader.onloadend = async () => {
        if (!loading) {
          setLoading(true);
          const fd = new FormData();
          fd.append('file', file);
          if (fileUpload) {
            const [info] = await adminApi.noticeApi.fileUpload(fd);
            if (info) {
              setLoading(false);
              setModal({
                open: true,
                text: '전송이 완료되었습니다.',
              });
              setValues({
                ...values,
                fileName: info.result.fileName,
                fileUrl: info.result.fileUrl,
              });
              event.target.value = '';
            } else {
              setLoading(false);
              setModal({
                open: true,
                text: '오류가 발생했습니다\n다시 시도해 주세요.',
              });
              event.target.value = '';
            }
          }
        }
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  return loading ? (
    <Loading />
  ) : step === 'add' ? (
    <>
      <AdminAlert modal={modal} setModal={setModal} />
      <Admin>
        <NoticeForm
          navigate={navigate}
          values={values}
          onChange={onChange}
          checkBoxChange={checkBoxChange}
          submit={submit}
          type="add"
          fileUpload={fileUpload}
        />
      </Admin>
    </>
  ) : (
    <NoticeListPresenter
      list={list}
      filters={filters}
      setFilters={setFilters}
      pages={pages}
      goDetail={goDetail}
      goAdd={goAdd}
    />
  );
}

export default NoticeListContainer;
