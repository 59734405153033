import React, { useState, useEffect } from 'react';
import { companyApi } from 'api';
import Loading from 'components/Element/Loading';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import CompanyDetail from 'components/Routes/Company/CompanyDetail';
import CompanyPresenter from './CompanyPresenter';

function CompanyContainer(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  const [list, setList] = useState();
  const [filter, setFilter] = useState();

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await companyApi.companyList({ filter });
    if (info) {
      setList(info.result);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, [filter]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <CompanyDetail
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        type="user"
      />
      <CompanyPresenter
        list={list}
        setModalOpen={setModalOpen}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
}

export default CompanyContainer;
