import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ColDiv, fadein, flexCol, RowDiv } from 'styles/CommonCSS';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import Button from 'components/Element/Button';
import Text from 'components/Element/Text';

function Alert({ modal, setModal }) {
  const [currentScrollTop, setCurrentScrollTop] = useState();

  useEffect(() => {
    if (modal) {
      setCurrentScrollTop(window.scrollY);
      document.body.style.cssText = `
        top: -${window.scrollY}px;
        overflow-y: overlay;
        width: 100%;`;
    } else {
      document.body.style.cssText = ``;
      window.scrollTo(0, currentScrollTop);
    }

    return () => {
      document.body.style.cssText = '';
    };
  }, [modal]);

  const closeModal = () => {
    setModal();
  };

  const onClickModal = () => {
    if (modal.onClick) {
      modal.onClick();
    }
    setModal();
  };

  return modal ? (
    <Wrap open={modal}>
      <Content>
        <RowDiv width="100%" justify="space-between" align="center">
          <Text mSize={18} weight={700}>
            {modal.title ? modal.title : '알림'}
          </Text>
          <Close onClick={closeModal} />
        </RowDiv>
        <ColDiv flex={1} width="100%" mt={10} justify="center">
          <Text
            mSize={14}
            style={{ whiteSpace: 'pre-wrap' }}
            lineHeight={1.7}
            mLineHeight={1.7}
          >
            {modal.text}
          </Text>
        </ColDiv>
        <RowDiv width="100%" justify="flex-end" gap={10}>
          {modal.cancel && (
            <Button
              text="취소"
              weight={700}
              color="white"
              onClick={closeModal}
            />
          )}
          <Button
            text="확인"
            weight={700}
            bgColor="main"
            color="white"
            onClick={onClickModal}
            active
          />
        </RowDiv>
      </Content>
    </Wrap>
  ) : (
    ''
  );
}

const Wrap = styled.div`
  ${flexCol('center', 'center')}
  ${(props) =>
    props.open &&
    css`
      ${fadein()}
    `};
  z-index: 9000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  max-width: 600px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  ${flexCol('center', 'space-between')}
  width: 100%;
  min-height: 178px;
  padding: 20px;
`;

const Close = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export default Alert;
