import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ColDiv } from 'styles/CommonCSS';
import hexToRGB from 'modules/hexToRGB';

function BorderCard({ children, justify, ratio, hover, ...props }) {
  return (
    <Container
      justify={justify || 'center'}
      ratio={ratio}
      hover={hover}
      {...props}
    >
      {children}
    </Container>
  );
}

const shadow = keyframes`  
 0% {
    -webkit-box-shadow: 0 0 0  rgba(0, 0, 0, 0);
            box-shadow: 0 0 0  rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 4px 10px rgba(39, 47, 52, 0.16);
            box-shadow: 0 4px 10px rgba(39, 47, 52, 0.16);
  }`;

const Container = styled(ColDiv)`
  border: 1px solid ${({ theme }) => hexToRGB(theme.colors.colorCF, 0.26)};
  border-radius: 6px;
  aspect-ratio: ${({ ratio }) => ratio || null};

  ${({ Hover }) =>
    Hover &&
    css`
      &:hover {
        -webkit-animation: ${shadow} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
        animation: ${shadow} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    `}
`;

export default BorderCard;
