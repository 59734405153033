/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Div } from 'styles/CommonCSS';
import NoHeader from 'Layout/NoHeader';
import { ReactComponent as LogoIcon } from 'assets/icon/logo.svg';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import Input from 'components/Input/Input';
import Button from 'components/Element/Button';
import Select from 'components/Input/Select';
import useSelectOpen from 'hooks/useSelectOpen';

function SignupPresenter({
  values,
  onChange,
  active,
  list,
  submit,
  invalids,
  setInvalids,
}) {
  const { isOpen, setIsOpen } = useSelectOpen();

  return (
    <NoHeader>
      <Div padding="0 10px" width="100%" maxWidth={500}>
        <Text size={20} weight={700}>
          프로필 입력
        </Text>
        <Text mt={6} size={12}>
          회원님의 정보를 입력하세요.
        </Text>
        <Div mt={60} position="relative">
          <Text mb={4} size={12}>
            닉네임
          </Text>
          <Input
            width="100%"
            name="nickname"
            value={values.nickname}
            onChange={onChange}
            invalids={invalids}
            setInvalids={setInvalids}
          />
          {invalids.nickname.invalid === true && (
            <TextP color="warningColor" size={12} pl={5}>
              {invalids.nickname.text}
            </TextP>
          )}
        </Div>
        <Div mt={20} position="relative">
          <Text mb={4} size={12}>
            회사 명
          </Text>
          <Select
            list={list}
            name="company"
            value={values.company}
            onChange={onChange}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            width="100%"
            invalids={invalids}
            setInvalids={setInvalids}
          />
          {invalids.company.invalid === true && (
            <TextP color="warningColor" size={12} pl={5}>
              {invalids.company.text}
            </TextP>
          )}
        </Div>
        <Button
          mt={40}
          width="100%"
          text="확인"
          active={active}
          onClick={submit}
        />
      </Div>
    </NoHeader>
  );
}

const Logo = styled(LogoIcon)``;
const TextP = styled(Text)`
  width: 300px;
  position: absolute;
  bottom: -17px;
`;
export default SignupPresenter;
