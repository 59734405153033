import React from 'react';
import { Div, RowDiv, nonScrollbar, ColDiv } from 'styles/CommonCSS';
import Text from 'components/Element/Text';
import Input from 'components/Input/Input';
import Button from 'components/Element/Button';
import hexToRGB from 'modules/hexToRGB';
import Admin from 'Layout/Admin';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import BgDiv from 'components/Common/BgDiv';
import styled from 'styled-components';
import ImageComponent from 'components/Element/ImageComponent';

function ChallengeDetailPresenter({ title, list, navigate, onClick }) {
  return (
    <Admin>
      <Div padding="60px 120px 40px" maxWidth={1060}>
        <Div>
          <BgDiv
            width={32}
            ratio="1/1"
            bgColor="opacityCF"
            radius={16}
            padding="0 13px 0 10px"
            onClick={() => navigate(-1)}
            pointer
          >
            <ArrowButton direction="left" />
          </BgDiv>
          <Div mt={20} pb={20}>
            <Text size={20} weight={700}>
              {title}
            </Text>
          </Div>
        </Div>
        <ScrollDiv gap={40}>
          {list?.map((item, index) => (
            <Div key={`challenge${index}`}>
              <RowDiv justify="space-between" align="flex-end">
                <RowDiv gap={10}>
                  <Div>
                    <Text size={14} weight={700} mb={4}>
                      닉네임
                    </Text>
                    <Input width={190} readOnly value={item.nickname} />
                  </Div>
                  <Div>
                    <Text size={14} weight={700} mb={4}>
                      휴대폰번호
                    </Text>
                    <Input width={190} readOnly value={item.phone} />
                  </Div>
                </RowDiv>
                <RowDiv gap={10}>
                  <Button
                    text="승인"
                    width={108}
                    height={40}
                    active
                    onClick={() => onClick(item.id, '1')}
                  />
                  <Button
                    text="거절"
                    width={108}
                    height={40}
                    onClick={() => onClick(item.id, '0')}
                  />
                </RowDiv>
              </RowDiv>
              <Div mt={4} height={400} bgColor={hexToRGB('#CFCFCF', 0.46)}>
                <ImageComponent
                  src={item?.target}
                  height="100%"
                  margin="auto"
                />
              </Div>
            </Div>
          ))}
        </ScrollDiv>
      </Div>
    </Admin>
  );
}

const ArrowButton = styled(ArrowIcon)`
  cursor: pointer;
  width: 9px;
  height: 16px;
  transform: ${({ direction }) =>
    direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ScrollDiv = styled(ColDiv)`
  ${nonScrollbar};
  height: calc(100vh - 196px);
  overflow-y: overlay;
`;

export default ChallengeDetailPresenter;
