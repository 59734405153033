/* eslint-disable no-plusplus */
import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import { flexRow, RowDiv } from 'styles/CommonCSS';
import styled from 'styled-components';
import hexToRGB from 'modules/hexToRGB';
import Text from 'components/Element/Text';

function ServerButtonPagenation({ pages, filters, setFilters }) {
  const changePage = (i) => {
    if (i >= 0 && i <= pages.totalPages && pages.currentPage !== i) {
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
      });
    }
  };

  const pageNum = () => {
    const currentGroup = Math.floor((pages.currentPage - 1) / 5);
    const forArray = [];
    const lastIndex =
      pages.totalPages > currentGroup * 5 + 5
        ? currentGroup * 5 + 5
        : pages.totalPages;

    for (let i = currentGroup * 5; i < lastIndex; i += 1) {
      forArray.push(
        <NumberButton
          key={i}
          active={i + 1 === pages.currentPage}
          onClick={() => {
            changePage(i + 1);
          }}
        >
          <Text size={12}>{i + 1}</Text>
        </NumberButton>,
      );
    }

    return forArray;
  };

  return (
    pages && (
      <RowDiv width="100%" align="center" justify="center" mt={10}>
        <RowDiv align="center" justify="center" gap={6} $wrap>
          {Math.floor((pages.currentPage - 1) / 5) !== 0 && (
            <ArrowButton
              direction="left"
              onClick={() =>
                changePage(
                  (Math.floor((pages.currentPage - 1) / 5) - 1) * 5 + 1,
                )
              }
            >
              <ArrowIcon />
            </ArrowButton>
          )}
          {pageNum()}
          {pages.totalPages >
            Math.floor((pages.currentPage - 1) / 5) * 5 + 5 && (
            <ArrowButton
              onClick={() =>
                changePage(Math.floor((pages.currentPage - 1) / 5) * 5 + 6)
              }
            >
              <ArrowIcon />
            </ArrowButton>
          )}
        </RowDiv>
      </RowDiv>
    )
  );
}

const ArrowButton = styled.div`
  background-color: ${({ theme }) => hexToRGB(theme.colors.colorCF, 0.1)};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  ${flexRow('center', 'center')}
  cursor:pointer;

  & > svg {
    transform: ${({ direction }) =>
      direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
  & > svg > path {
    fill: ${({ theme }) => theme.colors.font};
  }

  &:hover {
    opacity: 0.5;
  }
`;

const NumberButton = styled.div`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main : 'transparent'};
  color: ${({ active, theme }) => (active ? 'white' : theme.colors.font)};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  ${flexRow('center', 'center')}
  cursor:pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export default ServerButtonPagenation;
