import React, { useState, useEffect } from 'react';
import { isEmptyObjItems } from 'modules/checkData';
import Loading from 'components/Element/Loading';
import { accountApi } from 'api';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import SignupPresenter from './SignupPresenter';

function SignupContainer(props) {
  const navigate = useNavigate();
  const phone = sessionStorage.getItem('phone');
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'nickname']);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [invalids, setInvalids] = useState({
    nickname: { invalid: false, text: '' },
    company: { invalid: false, text: '' },
  });
  const [values, setValues] = useState({
    nickname: '',
    company: '',
    companyId: '',
    phone,
  });

  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(!isEmptyObjItems(values) && invalids.nickname.invalid !== true);
  }, [values]);

  const onChange = (value, name, id) => {
    let tempValues;
    if (id) {
      tempValues = { companyId: String(id) };
    }
    setInvalids({
      ...invalids,
      [name]: { invilids: false, text: '' },
    });
    setValues({ ...values, ...tempValues, [name]: value });
  };

  // 회사리스트 api
  const getList = async () => {
    const [info] = await accountApi.companyList();
    if (info) {
      setList(info.result);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, []);

  // 회원가입 api
  const submit = async () => {
    const params = { ...values };

    if (!isEmptyObjItems(params)) {
      delete params.company;
      const [info, error] = await accountApi.join(params);
      if (info) {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        setCookie('token', info.result.token, { path: '/', expires });
        setCookie('nickname', info.result.nickname, { path: '/', expires });
        sessionStorage.removeItem('phone');
        navigate('/');
      } else if (error.resCode === 409) {
        setActive(false);
        setInvalids({
          ...invalids,
          nickname: { invalid: true, text: '이미 사용 중인 닉네임입니다.' },
        });
      }
    } else if (!params.nickname && !params.company) {
      setInvalids({
        ...invalids,
        nickname: { invalid: true, text: '닉네임을 입력하세요.' },
        company: { invalid: true, text: '소속된 회사를 선택하세요.' },
      });
    } else if (!params.nickname) {
      setInvalids({
        ...invalids,
        nickname: { invalid: true, text: '닉네임을 입력하세요.' },
      });
    } else if (!params.company) {
      setInvalids({
        ...invalids,
        company: { invalid: true, text: '소속된 회사를 선택하세요.' },
      });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <SignupPresenter
      values={values}
      onChange={onChange}
      active={active}
      list={list}
      submit={submit}
      invalids={invalids}
      setInvalids={setInvalids}
    />
  );
}

export default SignupContainer;
