import React from 'react';
import { RowDiv, Div, ColDiv } from 'styles/CommonCSS';
import Admin from 'Layout/Admin';
import Text from 'components/Element/Text';
import Button from 'components/Element/Button';
import hexToRGB from 'modules/hexToRGB';
import moment from 'moment-timezone';
import ServerButtonPagenation from 'components/Element/ServerButtonPagenation';

function NoticeListPresenter({
  list,
  filters,
  setFilters,
  pages,
  goDetail,
  goAdd,
}) {
  return (
    <Admin>
      <Div padding="78px 40px 0" maxWidth={1060}>
        <RowDiv mt={10} mb={20} justify="space-between">
          <Text size={20} weight={700}>
            공지사항
          </Text>
          <Button
            text="등록"
            width={86}
            height={40}
            active
            onClick={() => goAdd()}
          />
        </RowDiv>
        <Div minHeight={468}>
          <RowDiv bgColor={hexToRGB('#007FC7', 0.1)} height={36} align="center">
            <ColDiv maxWidth={50} width="100%" align="center">
              <Text size={14} weight={700}>
                번호
              </Text>
            </ColDiv>
            <ColDiv maxWidth={690} width="100%" align="center">
              <Text size={14} weight={700}>
                제목
              </Text>
            </ColDiv>
            <ColDiv maxWidth={140} width="100%" align="center">
              <Text size={14} weight={700}>
                등록일
              </Text>
            </ColDiv>
            <ColDiv maxWidth={100} width="100%" align="center">
              <Text size={14} weight={700}>
                조회수
              </Text>
            </ColDiv>
          </RowDiv>
          {list.map((item, index) => (
            <RowDiv
              key={`item${index}`}
              height={36}
              align="center"
              onClick={() => goDetail(item.id)}
              pointer
            >
              <ColDiv maxWidth={50} width="100%" align="center">
                <Text size={14}>{pages.totalCount - (item.row_num - 1)}</Text>
              </ColDiv>
              <Div
                maxWidth={690}
                width="100%"
                overflowX="hidden"
                align="center"
              >
                <Text line={1}>{item.title}</Text>
              </Div>
              <ColDiv maxWidth={140} width="100%" align="center">
                <Text size={14}>
                  {moment(item.created_dt).format('YYYY-MM-DD')}
                </Text>
              </ColDiv>
              <ColDiv maxWidth={100} width="100%" align="center">
                <Text size={14}>{item.views}</Text>
              </ColDiv>
            </RowDiv>
          ))}
        </Div>
        <ServerButtonPagenation
          filters={filters}
          setFilters={setFilters}
          pages={pages}
        />
      </Div>
    </Admin>
  );
}

export default NoticeListPresenter;
