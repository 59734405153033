import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { rankingApi } from 'api';
import Loading from 'components/Element/Loading';
import RankingPresenter from './RankingPresenter';

function RankingContainer(props) {
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const [list, setList] = useState();
  const [filter, setFilter] = useState('person');
  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await rankingApi.ranking(filter);
    if (info) {
      const { result } = info;
      setList(result);
      setLoading(false);
    }
  };

  const onClick = (name) => {
    if (name === 'person') {
      setFilter('person');
    } else if (name === 'company') {
      setFilter('company');
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, [filter]);

  return loading ? (
    <Loading />
  ) : (
    <RankingPresenter list={list} onClick={onClick} filter={filter} />
  );
}

export default RankingContainer;
