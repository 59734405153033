import React, { useState, useEffect } from 'react';
import { challengeApi } from 'api';
import Loading from 'components/Element/Loading';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import moment from 'moment-timezone';
import ChallengeListPresenter from './ChallengeListPresenter';

function ChallengeListContainer(props) {
  const [loading, setLoading] = useState(true);
  const [monthLoading, setMonthLoading] = useState(true);
  const [monthList, setMonthList] = useState();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [cookies] = useCookies();
  const [filter, setFilter] = useState(moment().format('YYYY-MM'));

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await challengeApi.challengeList(filter);
    if (info) {
      const { result } = info;
      setList(result.list);
      setMonthList(result.monthList);
      setLoading(false);
      setMonthLoading(false);
    }
  };

  const onClick = (month) => {
    setFilter(month);
  };

  const goDetail = (id) => {
    if (id) {
      navigate(`${String(id)}`, { state: { id } });
    }
  };

  useEffect(() => {
    getList();
    return () => {
      setMonthLoading(true);
      setLoading(true);
    };
  }, [filter]);

  return (
    <ChallengeListPresenter
      monthList={monthList}
      list={list}
      onClick={onClick}
      goDetail={goDetail}
      filter={filter}
      monthLoading={monthLoading}
    />
  );

  // return loading ? (
  //   <Loading />
  // ) : (
  //   <ChallengeListPresenter
  //     monthList={monthList}
  //     list={list}
  //     onClick={onClick}
  //     goDetail={goDetail}
  //     filter={filter}
  //     monthLoading={monthLoading}
  //   />
  // );
}

export default ChallengeListContainer;
