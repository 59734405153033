import React, { useState, useEffect } from 'react';
import { adminApi } from 'api';
import Loading from 'components/Element/Loading';
import axios from 'axios';
import fileDownloader from 'modules/fileDownloader';
import { useLocation } from 'react-router-dom';
import DashboardPresenter from './DashboardPresenter';

function DashboardContainer(props) {
  const location = useLocation();
  const adminToken = sessionStorage.getItem('adminToken');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [filters, setFilters] = useState();
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
  });

  const [keyword, setKeyword] = useState();

  const search = () => {
    setFilters({ ...filters, keyword });
  };

  const onChange = (value) => {
    setKeyword(value);
  };

  const [total, setTotal] = useState();
  const getList = async () => {
    if (sessionStorage.adminToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info] = await adminApi.dashboardApi.dashboardList(filters);
      if (info) {
        const { result } = info;
        setPages({
          currentCount: result.currentCount,
          currentPage: result.currentPage,
          perPage: result.perPage,
          totalPages: result.totalPages,
          totalCount: result.totalCount,
        });
        setList(result.list);
        setTotal({
          joinCount: result.joinCount,
          challengeCount: result.challengeCount,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filters?.perPage === 10) {
      getList();
    }
    return () => setLoading(true);
  }, [filters]);

  useEffect(() => {
    setFilters({
      perPage: 10,
      page: 1,
      lastRowNum: 0,
      keyword: '',
    });
    setKeyword();
  }, [location]);

  // 엑셀 api
  const excel = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [data, dataError] = await adminApi.dashboardApi.downloadExcel();
    if (data) {
      const fileName = `sto_plaza_excel.xlsx`;
      fileDownloader(data, fileName);
    } else if (dataError) {
      console.log(dataError);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <DashboardPresenter
      list={list}
      total={total}
      filters={filters}
      setFilters={setFilters}
      pages={pages}
      excel={excel}
      onChange={onChange}
      keyword={keyword}
      setKeyword={setKeyword}
      search={search}
    />
  );
}

export default DashboardContainer;
