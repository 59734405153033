import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ColDiv, common } from 'styles/CommonCSS';
import hexToRGB from 'modules/hexToRGB';

function OpacityDiv({ children, ratio, radius, disabled, ...props }) {
  return (
    <Container
      align="center"
      display="flex"
      justify="center"
      ratio={ratio}
      radius={radius}
      disabled={disabled}
      {...props}
    >
      {children}
    </Container>
  );
}

const shadow = keyframes`  
 0% {
    -webkit-box-shadow: 0 0 0  rgba(0, 0, 0, 0);
            box-shadow: 0 0 0  rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 6px 10px rgba(0, 127, 199, 0.2);
            box-shadow: 0 6px 10px rgba(0, 127, 199, 0.2);
  }`;

const Container = styled(ColDiv)`
  ${common}
  border: 1px solid
    ${({ theme, opBorder }) =>
    opBorder ? hexToRGB(theme.colors[opBorder], 0.26) : 'none'};
  aspect-ratio: ${({ ratio }) => ratio || null};
  background-color: ${({ theme, bg }) =>
    bg && hexToRGB(theme.colors[bg], 0.16)};

  ${({ selected }) =>
    selected &&
    css`
      -webkit-animation: ${shadow} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: ${shadow} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      background-color: ${({ theme }) => theme.colors.main};
      & > p {
        color: white;
      }
    `}
  pointer-events:${({ disabled }) => disabled && 'none'}
`;

export default OpacityDiv;
