import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/Theme';
import GlobalStyle from 'styles/GlobalStyles';
import ScrollToTop from 'components/Element/ScrollToTop';
import IEComponent from 'components/Element/IE/IEComponent';
import checkIE from 'modules/checkIE';
import Main from 'routes/Main';
import Login from 'routes/Login';
import Signup from 'routes/Signup';

import Ranking from 'routes/Ranking';
import Company from 'routes/Company';
import Profile from 'routes/Profile';
import NotFound from 'routes/NotFound';
import NoticeDetail from 'components/Routes/Main/NoticeDetail';

import ChallengeMain from 'routes/Challenge/ChallengeMain';
import ChallengeList from 'routes/Challenge/ChallengeList';
import ChallengeDetail from 'routes/Challenge/ChallengeDetail';

import Admin from 'routes/Admin/Admin';
import AdminLogin from 'routes/Admin/Login';
import Dashboard from 'routes/Admin/Dashboard';

import AdminChallenge from 'routes/Admin/Challenge/AdminChallenge';
import AdminChallengeList from 'routes/Admin/Challenge/ChallengeList';
import AdminChallengeDetail from 'routes/Admin/Challenge/ChallengeDetail';

import AmdinNotice from 'routes/Admin/Notice/AmdinNotice';
import NoticeList from 'routes/Admin/Notice/NoticeList';
import AdminNoticeDetail from 'routes/Admin/Notice/NoticeDetail';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Loading from 'components/Element/Loading';

function App(props) {
  const isIE = checkIE();
  const [cookies] = useCookies();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (cookies.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
      setLoading(false);
    } else if (
      !cookies.token &&
      window.location.pathname !== '/login' &&
      !window.location.pathname.includes('admin')
    ) {
      window.location.href = '/login';
      setLoading(false);
    } else if (
      window.location.pathname === '/login' ||
      window.location.pathname.includes('admin')
    ) {
      setLoading(false);
    }
  }, []);

  return isIE ? (
    <IEComponent />
  ) : (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop>
          {loading ? (
            <Loading />
          ) : (
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Main />} />
              <Route path="/notice/:id" element={<NoticeDetail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/challenge" element={<ChallengeMain />}>
                <Route path="" element={<ChallengeList />} />
                <Route path=":id" element={<ChallengeDetail />} />
              </Route>
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/company" element={<Company />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/admin" element={<Admin />}>
                <Route path="login" element={<AdminLogin />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="challenge" element={<AdminChallenge />}>
                  <Route path="" element={<AdminChallengeList />} />
                  <Route path=":id" element={<AdminChallengeDetail />} />
                </Route>
                <Route path="notice" element={<AmdinNotice />}>
                  <Route path="" element={<NoticeList />} />
                  <Route path=":id" element={<AdminNoticeDetail />} />
                </Route>
              </Route>
            </Routes>
          )}
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
