import React from 'react';
import { RowDiv, Div, ColDiv } from 'styles/CommonCSS';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import Admin from 'Layout/Admin';
import OpacityDiv from 'components/Common/OpacityDiv';
import Input from 'components/Input/Input';
import Button from 'components/Element/Button';
import hexToRGB from 'modules/hexToRGB';
import ServerButtonPagenation from 'components/Element/ServerButtonPagenation';

function DashboardPresenter({
  list,
  total,
  pages,
  keyword,
  filters,
  setFilters,
  excel,
  search,
  setKeyword,
}) {
  return (
    <Admin>
      <Div padding="53px 40px 0" maxWidth={1060}>
        <RowDiv gap={20} mb={40}>
          <Div>
            <BorderDiv
              bgColor="main"
              width={230}
              height={36}
              justify="center"
              align="center"
            >
              <Text color="white" weight={700} size={14}>
                누적 가입자
              </Text>
            </BorderDiv>
            <OpacityDiv opBorder="colorCF" height={46}>
              <Text weight={700} size={17}>
                {total?.joinCount}
              </Text>
            </OpacityDiv>
          </Div>
          <Div>
            <BorderDiv
              bgColor="main"
              width={230}
              height={36}
              justify="center"
              align="center"
            >
              <Text color="white" weight={700} size={14}>
                누적 챌린지
              </Text>
            </BorderDiv>
            <OpacityDiv opBorder="colorCF" height={46}>
              <Text weight={700} size={17}>
                {total?.challengeCount}
              </Text>
            </OpacityDiv>
          </Div>
        </RowDiv>
        <RowDiv justify="space-between" mb={10}>
          <RowDiv gap={6}>
            <Input
              width={210}
              placeholder="검색어 입력"
              value={keyword}
              onChange={(val) => setKeyword(val)}
              active
              onKeyDown={search}
            />
            <Button
              text="검색"
              active
              width={86}
              height={40}
              onClick={search}
            />
          </RowDiv>
          <Button
            text="엑셀 다운로드"
            width={120}
            height={40}
            onClick={excel}
            active
          />
        </RowDiv>
        <Div mb={20} minHeight={396}>
          <RowDiv bgColor={hexToRGB('#007FC7', 0.1)} height={36} align="center">
            <ColDiv flex={2} align="center">
              <Text size={14} weight={700}>
                닉네임
              </Text>
            </ColDiv>
            <ColDiv flex={2} align="center">
              <Text size={14} weight={700}>
                휴대폰번호
              </Text>
            </ColDiv>
            <ColDiv flex={2} align="center">
              <Text size={14} weight={700}>
                누적 챌린지
              </Text>
            </ColDiv>
            <ColDiv flex={2} align="center">
              <Text size={14} weight={700}>
                누적 포인트
              </Text>
            </ColDiv>
            <ColDiv flex={2} align="center">
              <Text size={14} weight={700}>
                시즌 랭킹
              </Text>
            </ColDiv>
          </RowDiv>
          {list?.map((item, index) => (
            <RowDiv key={`item${index}`} height={36} align="center">
              <ColDiv flex={2} align="center">
                <Text size={14}>{item.nickname}</Text>
              </ColDiv>
              <ColDiv flex={2} align="center">
                <Text size={14}>
                  {item.phone
                    .replace(/82/, '0')
                    .replace(' ', '')
                    .replace(/(\d{3})(\d{3,4})(\d{4})/, '$1 $2 $3')}
                </Text>
              </ColDiv>
              <ColDiv flex={2} align="center">
                <Text size={14}>{item.count}</Text>
              </ColDiv>
              <ColDiv flex={2} align="center">
                <Text size={14}>{item.point}</Text>
              </ColDiv>
              <ColDiv flex={2} align="center">
                <Text size={14}>{item.rank}</Text>
              </ColDiv>
            </RowDiv>
          ))}
        </Div>
        <ServerButtonPagenation
          filters={filters}
          setFilters={setFilters}
          pages={pages}
        />
      </Div>
    </Admin>
  );
}

const BorderDiv = styled(ColDiv)`
  border-radius: 4px 4px 0 0;
`;

export default DashboardPresenter;
