import React from 'react';
import { RowDiv, Div, Absolute, ColDiv } from 'styles/CommonCSS';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import Input from 'components/Input/Input';
import Button from 'components/Element/Button';

function LoginPresenter({
  values,
  onChange,
  phoneActive,
  codeActive,
  isRunning,
  minutes,
  seconds,
  sendCode,
  checkCode,
  invalids,
}) {
  return (
    <Wrap align="center" justify="center" width="100%" height="100%">
      <ColDiv
        width={398}
        bgColor="white"
        align="center"
        padding="40px 20px"
        radius={8}
      >
        <Text size={20} weight={700}>
          로그인
        </Text>
        <RowDiv gap={6} mt={40}>
          <Div position="relative">
            <Input
              placeholder="휴대폰번호 입력"
              name="phone"
              value={values.phone}
              onChange={onChange}
              width={210}
              height={44}
            />
            {(invalids.phone.invalid === true ||
              invalids.phone.invalid === 'success') && (
              <TextP color="warningColor" size={12} pl={5}>
                {invalids.phone.text}
              </TextP>
            )}
            {isRunning && (
              <Absolute top="13px" mTop="14px" right="12px">
                <Text color="warningColor">
                  {`0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
                </Text>
              </Absolute>
            )}
          </Div>
          <Button
            text="인증번호 요청"
            color="white"
            onClick={sendCode}
            active={phoneActive}
            width={122}
            height={44}
          />
        </RowDiv>
        <RowDiv gap={6} mt={30}>
          <Div position="relative">
            <Input
              placeholder="인증번호 입력"
              name="code"
              value={values.code}
              onChange={onChange}
              width={210}
              height={44}
            />
            {invalids.code.invalid === true && (
              <TextP color="warningColor" size={12} pl={5}>
                {invalids.code.text}
              </TextP>
            )}
          </Div>
          <Button
            text="인증번호 확인"
            color="white"
            onClick={checkCode}
            active={codeActive}
            width={122}
            height={44}
          />
        </RowDiv>
      </ColDiv>
    </Wrap>
  );
}

const Wrap = styled(ColDiv)`
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 32, 0.36);
  backdrop-filter: blur(10px);
`;

const TextP = styled(Text)`
  width: 300px;
  position: absolute;
  bottom: -17px;
`;
export default LoginPresenter;
