import React, { useState, useEffect } from 'react';
import AdminAlert from 'components/Element/Modal/AdminAlert';
import { adminApi } from 'api';
import Loading from 'components/Element/Loading';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ChallengeDetailPresenter from './ChallengeDetailPresenter';

function ChallengeDetailContainer(props) {
  const navigate = useNavigate();
  const adminToken = sessionStorage.getItem('adminToken');
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  const [title, setTitle] = useState('');
  const { id } = useParams();

  const getList = async () => {
    if (id) {
      setLoading(true);
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info] = await adminApi.challengeApi.challengeDetail(id);
      if (info) {
        setList(info.result.list);
        setTitle(info.result.title);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, []);

  const alert = (id, isPass) => {
    setModal({
      open: true,
      text: isPass === '0' ? '거절하시겠습니까?' : '승인하시겠습니까?',
      cancel: true,
      onClick: () => confirm(id, isPass),
    });
  };

  const confirm = async (logId, isPass) => {
    const [info] = await adminApi.challengeApi.challengeConfirm(id, logId, {
      isPass,
    });
    if (info) {
      getList();
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <>
      <AdminAlert modal={modal} setModal={setModal} />
      <ChallengeDetailPresenter
        title={title}
        list={list}
        navigate={navigate}
        onClick={alert}
      />
    </>
  );
}

export default ChallengeDetailContainer;
