import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import styled from 'styled-components';
import { ColDiv, Div, flexCol } from 'styles/CommonCSS';
import BasicModal from 'components/Element/Modal/BasicModal';
import { challengeApi } from 'api';
import { useParams } from 'react-router-dom';
import Text from 'components/Element/Text';
import Loading from 'components/Element/Loading';

function QRScanner({ modalOpen, setModalOpen }) {
  const { id } = useParams();
  const previewStyle = {
    height: '100%',
    width: '100%',
  };
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  let isRunning = false;

  const handleError = (err) => {
    console.error(err);
    setAlert('올바르지 않은 QR입니다.');
  };

  const handleScan = (result) => {
    if (!loading) {
      if (result?.text) {
        checkCode(result.text);
      }
    }
  };

  const checkCode = async (code) => {
    if (!isRunning) {
      isRunning = true;
      setLoading(true);
      const [stampInfo, error] = await challengeApi.challengeCode(id, { code });
      if (stampInfo) {
        setAlert('스캔이 완료되었습니다.');
        setTimeout(() => {
          setAlert('');
          setModalOpen(false);
        }, [2000]);
      } else if (error?.resCode === 409) {
        setAlert('이미 완료한 챌린지입니다.');
        setTimeout(() => {
          setAlert('');
          setModalOpen(false);
        }, [2000]);
      } else if (error?.resCode === 413) {
        setAlert('챌린지는 1일 최대 3회까지 수행이 가능합니다.');
        setTimeout(() => {
          setAlert('');
          setModalOpen(false);
        }, [2000]);
      } else if (error?.resCode === 414) {
        setAlert('QR을 순서대로 스캔해주세요');
        setTimeout(() => {
          setAlert('');
          setModalOpen(false);
        }, [2000]);
      } else {
        setAlert('올바르지 않은 QR입니다.');
        setTimeout(() => {
          setAlert('');
          setModalOpen(false);
        }, [2000]);
      }
      setLoading(false);
    }
  };

  return (
    <BasicModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noBottomButton
    >
      {loading && <Loading />}
      <QRContainer>
        {alert && (
          <ColDiv
            width="calc(100% - 40px)"
            height="100px"
            position="absolute"
            bgColor="white"
            top="50%"
            left="50%"
            radius="5px"
            transform="translate(-50%,-50%)"
            zIndex="9999"
            align="center"
            justify="center"
          >
            <Text size={14}>{alert}</Text>
          </ColDiv>
        )}
        <QrReader
          constraints={{ facingMode: 'environment' }}
          delay={3000}
          style={previewStyle}
          onError={handleError}
          onResult={handleScan}
        />
      </QRContainer>
    </BasicModal>
  );
}

const QRContainer = styled(Div)`
  width: 100%;
  height: 100%;
  position: relative;
  & > section {
    ${flexCol('center', 'center')}
  }
`;

export default QRScanner;
