import React, { useState, useEffect } from 'react';
import { mainApi } from 'api';
import Loading from 'components/Element/Loading';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import MainPresenter from './MainPresenter';

function MainContainer(props) {
  const [cookies] = useCookies(['token', 'nickname']);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [noticeLoading, setNoticeLoading] = useState(true);
  const [nickName, setNickName] = useState(cookies.nickname || '');

  useEffect(() => {
    if (!cookies.token) {
      setLoading(false);
      navigate('/login');
    } else {
      setNickName(cookies.nickname);
      setLoading(false);
    }
    return () => setLoading(true);
  }, []);

  const [list, setList] = useState();
  const [filters, setFilters] = useState({
    perPage: 5,
    page: 1,
    lastRowNum: 0,
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 5,
    totalPages: 0,
  });
  const [info, setInfo] = useState();

  const [fixedNotices, setFixedNotices] = useState();
  const getList = async () => {
    if (cookies.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
      const [info] = await mainApi.noticeList(filters);

      if (info) {
        const { result } = info;
        setPages({
          currentCount: result.currentCount,
          currentPage: result.currentPage,
          perPage: result.perPage,
          totalPages: result.totalPages,
          totalCount: result.totalCount,
        });
        setFixedNotices(result.fixedNotices);
        setList(result.list);
        setInfo({
          myPoint: result.myPoint,
          myRank: result.myRank,
          nickname: result.nickname,
        });
        setNoticeLoading(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getList();
    return () => {
      setNoticeLoading(true);
      setLoading(true);
    };
  }, [filters]);

  const goDetail = (id) => {
    if (id) {
      navigate(`/notice/${String(id)}`, { state: { id } });
    }
  };
  return (
    <MainPresenter
      list={list}
      filters={filters}
      setFilters={setFilters}
      pages={pages}
      goDetail={goDetail}
      nickName={nickName}
      fixedNotices={fixedNotices}
      noticeLoading={noticeLoading}
      info={info}
    />
  );

  // return loading ? (
  //   <Loading />
  // ) : (
  //   <MainPresenter
  //     list={list}
  //     filters={filters}
  //     setFilters={setFilters}
  //     pages={pages}
  //     goDetail={goDetail}
  //     nickName={nickName}
  //     fixedNotices={fixedNotices}
  //     noticeLoading={noticeLoading}
  //     info={info}
  //   />
  // );
}

export default MainContainer;
