import React, { useEffect } from 'react';
import { RowDiv, ColDiv, scrollbar } from 'styles/CommonCSS';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import Input from 'components/Input/Input';
import Radio from 'components/Input/Radio';
import Select from 'components/Input/Select';
import useSelectOpen from 'hooks/useSelectOpen';
import Textarea from 'components/Input/Textarea';
import BasicModal from 'components/Element/Modal/BasicModal';
import moment from 'moment-timezone';
import BtnInputFile from 'components/Input/BtnInputFile';
import Button from 'components/Element/Button';

function AdminForm({
  modalOpen,
  setModalOpen,
  values,
  setValues,
  monthList,
  onChange,
  radioChange,
  onClickHandler,
  fileUpload,
}) {
  const { isOpen, setIsOpen } = useSelectOpen();

  useEffect(() => {
    if (modalOpen) {
      if (modalOpen !== 'add') {
        setValues({
          id: modalOpen.id,
          title: modalOpen.title,
          type: modalOpen.type,
          point: modalOpen.point,
          description: modalOpen.description,
          codes: modalOpen.codes,
          year: moment(modalOpen.end_dt).format('YYYY'),
          month: moment(modalOpen.end_dt).format('MM'),
          isDup: modalOpen.isDup,
        });
      } else {
        setValues({
          id: modalOpen.id,
          title: '',
          type: '',
          point: '',
          description: '',
          codes: '',
          year: '2022',
          month: '',
          isDup: '',
        });
      }
    }
  }, [modalOpen]);

  const typeList = ['qr', 'image'];
  const isDupList = ['1', '0'];
  return (
    <BasicModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClickHandler={onClickHandler}
      type="admin"
    >
      <ScrollDiv padding="0 30px 30px 30px">
        <Text size={20} weight={700} textAlign="center">
          챌린지 {modalOpen === 'add' ? '추가하기' : '수정하기'}
        </Text>
        <ColDiv gap={20} mt={30}>
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              제목
            </Text>
            <Input
              placeholder="20자 이내로 입력하세요"
              width="100%"
              name="title"
              value={values.title}
              onChange={onChange}
              maxLength={20}
            />
          </ColDiv>
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              타입
            </Text>
            <RowDiv gap={60}>
              <Radio
                checked={values.type === typeList[0]}
                name="type"
                value="qr"
                onChange={radioChange}
                disabled={modalOpen !== 'add'}
              >
                QR
              </Radio>
              <Radio
                checked={values.type === typeList[1]}
                name="type"
                value="image"
                onChange={radioChange}
                disabled={modalOpen !== 'add'}
              >
                이미지
              </Radio>
            </RowDiv>
          </ColDiv>
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              중복 여부
            </Text>
            <RowDiv gap={60}>
              <Radio
                checked={values.isDup === isDupList[0]}
                name="isDup"
                value="1"
                onChange={radioChange}
                disabled={modalOpen !== 'add'}
              >
                허용
              </Radio>
              <Radio
                checked={values.isDup === isDupList[1]}
                name="isDup"
                value="0"
                onChange={radioChange}
                disabled={modalOpen !== 'add'}
              >
                미허용
              </Radio>
            </RowDiv>
          </ColDiv>
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              포인트
            </Text>
            <Input
              width="100%"
              placeholder="숫자만 입력하세요"
              type="number"
              name="point"
              value={values.point}
              onChange={onChange}
              readOnly={modalOpen !== 'add'}
            />
          </ColDiv>
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              진행기간
            </Text>
            <RowDiv gap={20}>
              <Input
                maxWidth={190}
                width="100%"
                type="number"
                name="year"
                value={values.year}
                onChange={onChange}
                readOnly={modalOpen !== 'add'}
                maxLength="4"
              />
              <Select
                list={monthList}
                name="month"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                value={values.month}
                type="admin"
                onChange={onChange}
                disabled={modalOpen !== 'add'}
              />
            </RowDiv>
          </ColDiv>
          {values.type === 'qr' && (
            <ColDiv gap={6}>
              <Text size={14} weight={700}>
                QR 코드
              </Text>
              <Input
                width="100%"
                placeholder="숫자와 콤마(,)만 입력하세요"
                name="codes"
                readOnly={modalOpen !== 'add'}
                value={
                  modalOpen === 'add'
                    ? values.codes
                    : values.codes.replace(/[^(1-9),]/gi, '')
                }
                onChange={onChange}
              />
            </ColDiv>
          )}
          <ColDiv gap={6}>
            <Text size={14} weight={700}>
              설명
            </Text>
            <Textarea
              width="100%"
              placeholder="내용을 입력하세요"
              name="description"
              value={values.description}
              onChange={onChange}
              minHeight={180}
            />
            <BtnInputFile
              name="file"
              fileUpload={fileUpload}
              text="이미지 업로드"
              bgColor="main"
            />
          </ColDiv>
        </ColDiv>
      </ScrollDiv>
    </BasicModal>
  );
}

const ScrollDiv = styled(ColDiv)`
  ${scrollbar};
  height: calc(100vh - 196px);
  overflow-y: overlay;
`;

export default AdminForm;
