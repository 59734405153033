import Text from 'components/Element/Text';
import React from 'react';
import styled from 'styled-components';
import { space, flexCol, elementSize, RowDiv } from 'styles/CommonCSS';

function BtnInputFile({
  value,
  setValues,
  name,
  fileUpload,
  readOnly,
  accept,
  onChange,
  bgColor,
  text,
  disabled,
  ...props
}) {
  const onChangeHandler = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const fd = new FormData();
      fd.append('file', file);
      fileUpload(fd);
    }
  };

  return (
    <Wrap htmlFor={name} bgColor={bgColor} readOnly={readOnly} {...props}>
      <input
        id={name}
        type="file"
        accept={accept}
        name={name}
        onChange={onChangeHandler}
        style={{ display: 'none' }}
      />
      <RowDiv width="100%" align="center" justify="center" gap={10}>
        <Text color="white" weight={700} size={14}>
          {text}
        </Text>
      </RowDiv>
    </Wrap>
  );
}

const Wrap = styled.label`
  ${flexCol('center', 'center')}
  ${elementSize}
  ${space} 

  position: relative;
  width: 100%;
  min-height: 40px;
  border-radius: 20px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.main};
`;

export default BtnInputFile;
