import React from 'react';
import { Span } from 'styles/CommonCSS';
import OpacityDiv from 'components/Common/OpacityDiv';
import moment from 'moment-timezone';
import Detail from 'Layout/Detail';
import Text from 'components/Element/Text';
import Button from 'components/Element/Button';
import ImageComponent from 'components/Element/Image';
import InputFile from 'components/Input/InputFile';
import Toast from 'components/Element/Toast';

function ChallengeDetailPresenter({
  item,
  fileUpload,
  toastText,
  setToastText,
  setQrOpen,
}) {
  return (
    <Detail>
      <Text mt={20} mb={20} weight={700} size={17}>
        챌린지 상세 정보
      </Text>
      {item.image && (
        <ImageComponent mb={10} width="100%" maxHeight={200} src={item.image} />
      )}
      <Text weight={700} size={14}>
        {item.title}
      </Text>
      <Text color="subFont" mt={10} mb={20}>
        {item.description}
      </Text>
      <OpacityDiv
        mb={40}
        bg="main"
        opBorder="main"
        height={107}
        padding="30px 0 20px"
        radius={6}
      >
        <Text size={24} weight={700} color="main">
          {item.point}&nbsp;
          <Span color="font" weight={400}>
            P
          </Span>
        </Text>
        <Text size={12} color="subFont" mt={17} lineHeight="20px">
          {moment(item.start_dt).format('YYYY-MM-DD')}&nbsp;~&nbsp;
          {moment(item.end_dt).format('YYYY-MM-DD')}
        </Text>
      </OpacityDiv>
      {item.type === 'qr' ? (
        <Button
          text="QR 스캔하기"
          bgColor="main"
          width="100%"
          size={12}
          onClick={() => {
            setQrOpen({ open: true });
          }}
        />
      ) : (
        <InputFile
          type="user"
          text="이미지 업로드"
          bgColor="main"
          width="100%"
          border="main"
          radius={20}
          height={38}
          name="fileName"
          fileUpload={fileUpload}
        />
      )}
      {toastText && <Toast toastText={toastText} setToastText={setToastText} />}
    </Detail>
  );
}

export default ChallengeDetailPresenter;
