import React from 'react';
import { RowDiv, Div, ColDiv } from 'styles/CommonCSS';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import bg from 'assets/graphic/bg.jpg';
import MobileHeader from 'components/Common/MobileHeader';
import hexToRGB from 'modules/hexToRGB';
import OpacityDiv from 'components/Common/OpacityDiv';
import RankingDiv from 'components/Routes/Ranking/RankingDiv';
import ResultAnimation from 'components/Routes/Ranking/ResultAnimation';
import Firecracker from 'components/Routes/Ranking/Firecracker/Firecracker';

function RankingPresenter({ list, onClick, filter }) {
  return (
    <BgDiv height="100vh">
      {/* <ResultAnimation /> */}
      <Firecracker />
      <MobileHeader position="fixed" />
      <Img src={bg} />

      <Div padding="46px 30px" width="100%">
        <Div mt={20}>
          <Text size={20} color="white" weight={700} mb={20}>
            챌린지 랭킹
          </Text>
        </Div>
        <ColDiv width="100%" align="center">
          <RowDiv
            bgColor="#1e313b"
            width={192}
            height={36}
            radius={19}
            position="relative;"
            zIndex={10}
          >
            <ColDiv
              flex={5}
              justify="center"
              align="center"
              radius={19}
              bgColor={filter === 'person' ? '#4ebefe' : null}
              onClick={() => onClick('person')}
              pointer
            >
              <Text weight={700} color="white" size={14}>
                개인
              </Text>
            </ColDiv>
            <ColDiv
              flex={5}
              justify="center"
              align="center"
              radius={19}
              bgColor={filter === 'company' ? '#4ebefe' : null}
              onClick={() => onClick('company')}
              pointer
            >
              <Text weight={700} color="white" size={14}>
                단체
              </Text>
            </ColDiv>
          </RowDiv>
        </ColDiv>
        <ColDiv align="center" mt={65}>
          <Col
            width={190}
            ratio="1/1"
            bgColor={hexToRGB('#ffffff', 0.16)}
            align="center"
            justify="center"
          >
            <Col
              width={150}
              ratio="1/1"
              bgColor={hexToRGB('#ffffff', 0.16)}
              align="center"
              justify="center"
              position="relative"
            >
              <Col width={120} ratio="1/1" bgColor="white" align="center">
                <Text color="rankColor" weight={700} size={14} mt="11.67%">
                  {(filter === 'company' && '단체 순위') || '내 순위'}
                </Text>
                <Text size={42} weight={700} mt={6}>
                  {list.myRank}
                </Text>
                <ColDiv
                  bgColor="rankColor"
                  width={74}
                  height={30}
                  radius={16}
                  align="center"
                  justify="center"
                  position="absolute"
                  bottom="0"
                >
                  <Text weight={700} color="white" size={12}>
                    {list.myPoint} P
                  </Text>
                </ColDiv>
              </Col>
            </Col>
          </Col>
        </ColDiv>
        <OpacityDiv
          margin="21px auto"
          maxWidth={550}
          padding="20px"
          bgColor={hexToRGB('#ffffff', 0.36)}
          radius={12}
          opBorder="colorCF"
          position="relative"
        >
          {list.list[0] && (
            <Div>
              <Div padding="0 16px 13px 16px">
                <RankingDiv ranking="1" type="first" item={list.list[0]} />
              </Div>
              <Text size={12} weight={700} textAlign="center" mt={2}>
                {list.list[0].nickname}
              </Text>
              <Text size={12} weight={700} textAlign="center" mt={2}>
                {list.list[0].point} P
              </Text>
            </Div>
          )}
          <Div position="relative" width="100%" height={70}>
            <RowDiv
              justify="space-between"
              position="absolute"
              top="-45px"
              width="100%"
            >
              {list.list[1] && (
                <Div>
                  <Div padding="0 13px 13px">
                    <RankingDiv ranking="2" type="second" item={list.list[1]} />
                  </Div>
                  <Text size={12} weight={700} textAlign="center" mt={2}>
                    {list.list[1].nickname}
                  </Text>
                  <Text size={12} weight={700} textAlign="center" mt={2}>
                    {list.list[1].point} P
                  </Text>
                </Div>
              )}
              {list.list[2] && (
                <Div>
                  <Div padding="0 13px 13px">
                    <RankingDiv ranking="3" type="third" item={list.list[2]} />
                  </Div>
                  <Text size={12} weight={700} textAlign="center" mt={2}>
                    {list.list[2].nickname}
                  </Text>
                  <Text size={12} weight={700} textAlign="center" mt={2}>
                    {list.list[2].point} P
                  </Text>
                </Div>
              )}
            </RowDiv>
          </Div>
        </OpacityDiv>
        <ColDiv gap={10} maxWidth={550} margin="auto">
          {list.list[3] && (
            <OpacityDiv
              bgColor={hexToRGB('#ffffff', 0.36)}
              radius={12}
              opBorder="colorCF"
              padding="10px"
            >
              <RowDiv align="center" width="100%" gap={10}>
                <Text weight={700}>{list.list[3].rank} 위</Text>
                <User src={list.list[3].thumbnail} />
                <ColDiv flex={10}>
                  <Text weight={700}> {list.list[3].nickname}</Text>
                </ColDiv>
                <Text weight={700}>{list.list[3].point} P</Text>
              </RowDiv>
            </OpacityDiv>
          )}
          {list.list[4] && (
            <OpacityDiv
              bgColor={hexToRGB('#ffffff', 0.36)}
              radius={12}
              opBorder="colorCF"
              padding="10px"
            >
              <RowDiv align="center" width="100%" gap={10}>
                <Text weight={700}>{list.list[4].rank} 위</Text>
                <User src={list.list[4].thumbnail} />
                <ColDiv flex={10}>
                  <Text weight={700}>{list.list[4].nickname}</Text>
                </ColDiv>
                <Text weight={700}>{list.list[4].point} P</Text>
              </RowDiv>
            </OpacityDiv>
          )}
        </ColDiv>
      </Div>
    </BgDiv>
  );
}

const BgDiv = styled(Div)`
  max-width: 768px;
  width: 100%;
  margin: auto;
`;

const Col = styled(ColDiv)`
  border-radius: 50%;
`;

const User = styled.img`
  width: 40px;
  height: 40px;
`;

const Img = styled.img`
  position: fixed;
  width: 768px;
  z-index: -1;
`;

export default RankingPresenter;
