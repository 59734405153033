import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as Paper } from 'assets/icon/paper.svg';
import Loading from 'components/Element/Loading';

function ResultAnimation(props) {
  const [loading, setLoading] = useState(true);
  const [snowFlakes, setSnowFlakes] = useState([]);
  const [style, setStyle] = useState([]);
  const [rotate, setRotate] = useState([]);
  const [randomLeft, setRandomLeft] = useState([]);

  const makeSnowFlakes = () => {
    const arr = Array.from('WeventAnimationWeventAnimation');
    setSnowFlakes(arr);
    const colorArray = ['#6BD6E2', '#DF4B73', '#FFE48B', '#C18BFF', '#FFB38B'];
    let animationDelay = '0s';
    let animationDuration = '3.5s';
    let width = '39px';
    let tempRandomLeft = [];
    let tempRotate = [];
    let tempStyle = [];
    let fill = '#6BD6E2';

    arr.map((el, i) => {
      animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
      animationDuration = `${(Math.random() * 2.5 + 2.5).toFixed(2)}s`;
      width = `${Math.floor(Math.random() * 25 + 14)}px`;
      fill = `${colorArray[Math.floor(Math.random() * 5)]}`;
      tempRandomLeft = [...tempRandomLeft, Math.floor(Math.random() * 100)];
      tempRotate = [...tempRotate, Math.floor(Math.random() * 90 + 90)];
      tempStyle = [
        ...tempStyle,
        {
          width,
          animationDelay,
          animationDuration,
          fill,
          left: `${tempRandomLeft}%`,
          transform: `translateX(-${tempRotate}%)`,
        },
      ];

      return false;
    });

    setRandomLeft(tempRandomLeft);
    setRotate(tempRotate);
    setStyle(tempStyle);
    setLoading(false);
  };

  useEffect(() => {
    makeSnowFlakes();

    return () => {
      setLoading(true);
    };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <AnimationContainer>
      <PaperContainer>
        {snowFlakes.map((c, i) => (
          <PaperIcon
            key={`anim${i}`}
            style={style[i]}
            rotate={rotate[i]}
            left={randomLeft[i]}
          />
        ))}
      </PaperContainer>
    </AnimationContainer>
  );
}

const AnimationContainer = styled.div`
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 768px;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
`;

const fall = (rotate, left) => keyframes`
  0% {
    top:-40px;
    left : ${left}%;
    transform: translateX(-${left}%) rotateX(${rotate}deg);
  }
  25% {
    left : ${left - 5}%;
    transform: translateX(-${left - 5}%) rotateX(0);
  }
  50% {
    left : ${left}%;
    transform: translateX(-${left}%) rotateX(${rotate}deg);
  }
  70% {
    left : ${left + 5}%;
    transform: translateX(-${left + 5}%) rotateX(${rotate - 180}deg);
  }
  100% {
    top:100%;
    left : ${left}%;
    transform: translateX(-${left}%) rotateX(${rotate - 360}deg);
  }`;

const PaperContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PaperIcon = styled(Paper)`
  position: absolute;
  top: -40px;
  animation: ${(props) => fall(props.rotate, props.left)} 3.5s ease-in infinite;
`;

export default ResultAnimation;
