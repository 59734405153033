import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import './writer.css';

function Writer({ value, name, onChange, imagePath }) {
  return (
    <CKEditor
      editor={Editor}
      config={{
        fontSize: {
          options: [
            14,
            15,
            16,
            17,
            18,
            19,
            'default',
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
          ],
        },
        simpleUpload: {
          uploadUrl: `${process.env.REACT_APP_API_HOST}/${imagePath}`,
        },
        placeholder: '내용을 입력하세요.',
      }}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data, name);
      }}
    />
  );
}

export default Writer;
