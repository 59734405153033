import axios from 'axios';

const makeRequest = (type, path, params) => {
  if (type === 'get') {
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'post') {
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'patch') {
    return axios.patch(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'delete') {
    return axios.delete(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'fileUpload') {
    // upload file
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, params);
  } else if (type === 'excelDownload' || type === 'zipDownload') {
    // download file
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      type:
        type === 'excel'
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : '.zip,.rar,.7zip',
      responseType: 'blob',
    });
  }
};

const getResults = async (type, path, params = {}) => {
  try {
    const {
      data: { results },
      data,
    } = await makeRequest(type, path, params);
    return [results || data, null];
  } catch (e) {
    console.error(e);
    return [null, e.response?.data, e.response?.status];
  }
};

export const codeApi = {
  send: (phone) => getResults('get', `client/verifiedCode?phone=${phone}`),
  code: (phone, code) =>
    getResults(
      'get',
      `client/verifiedCode/confirm?phone=${phone}&code=${code}`,
    ),
};

export const accountApi = {
  join: (params) => getResults('post', `client/account/join`, params),
  companyList: () => getResults('get', `client/account/company`),
  profile: (params) => getResults('get', `client/account`, params),
  modify: (params) => getResults('patch', `client/account`, params),
};

export const companyApi = {
  companyList: (params) => getResults('get', `client/company`, params),
};

export const mainApi = {
  noticeList: (params) => getResults('get', `client/main`, params),
  noticeDetail: (noticeId) => getResults('get', `client/notice/${noticeId}`),
};

export const challengeApi = {
  challengeList: (month) =>
    getResults('get', `client/challenge?yearMonth=${month}`),
  fileUpload: (file, challengeId) =>
    getResults(
      'fileUpload',
      `client/challenge/${challengeId}/upload/file`,
      file,
    ),
  challengeDetail: (challengeId) =>
    getResults('get', `client/challenge/${challengeId}`),
  challengeCode: (challengeId, params) =>
    getResults('post', `client/challenge/${challengeId}`, params),
};

export const rankingApi = {
  ranking: (type) => getResults('get', `client/rank/${type}`),
};

export const adminApi = {
  codeApi: {
    send: (phone) => getResults('get', `admin/verifiedCode?phone=${phone}`),
    code: (phone, code) =>
      getResults(
        'get',
        `admin/verifiedCode/confirm?phone=${phone}&code=${code}`,
      ),
  },
  dashboardApi: {
    dashboardList: (params) => getResults('get', `admin/dashboard`, params),
    downloadExcel: () =>
      getResults('excelDownload', `admin/dashboard/excel/download`),
  },
  noticeApi: {
    noticeList: (params) => getResults('get', `admin/notice`, params),
    noticeDetail: (noticeId) => getResults('get', `admin/notice/${noticeId}`),
    addNotice: (params) => getResults('post', `admin/notice`, params),
    modifyNotice: (noticeId, params) =>
      getResults('patch', `admin/notice/${noticeId}`, params),
    deleteNotice: (noticeId) =>
      getResults('delete', `admin/notice/${noticeId}`),
    fileUpload: (file) =>
      getResults('fileUpload', 'admin/notice/upload/file', file),
  },
  challengeApi: {
    challengeList: (year, month) =>
      getResults('get', `admin/challenge/?year=${year}&month=${month}`),
    challengeDetail: (challengeId) =>
      getResults('get', `admin/challenge/${challengeId}`),
    challengeConfirm: (challengeId, logId, params) =>
      getResults('post', `admin/challenge/${challengeId}/${logId}`, params),
    addChallenge: (params) => getResults('post', `admin/challenge`, params),
    modifyChallenge: (challengeId, params) =>
      getResults('patch', `admin/challenge/${challengeId}`, params),
    deleteChallenge: (challengeId) =>
      getResults('delete', `admin/challenge/${challengeId}`),
    fileUpload: (file) =>
      getResults('fileUpload', 'admin/challenge/upload/file', file),
    downloadExcel: () =>
      getResults('excelDownload', `admin/dashboard/excel/download2`),
  },
};
