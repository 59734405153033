import React, { useState, useEffect } from 'react';
import { adminApi } from 'api';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from 'components/Element/Loading';
import AdminAlert from 'components/Element/Modal/AdminAlert';
import NoticeDetailPresenter from './NoticeDetailPresenter';

function NoticeDetailContainer(props) {
  const navigate = useNavigate();
  const adminToken = sessionStorage.getItem('adminToken');
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState();
  const { state } = useLocation();
  const { id } = state;

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.noticeApi.noticeDetail(id);
    if (info) {
      setValues({ ...info.result, isFixed: String(info.result.is_fixed) });
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, []);

  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  const checkBoxChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      setValues({ ...values, [name]: '1' });
    } else {
      setValues({ ...values, [name]: '0' });
    }
  };

  const submit = (type) => {
    if (type === 'modify') {
      setModal(modifyMsg);
    } else if (type === 'add') {
      setModal(submitMsg);
    }
  };

  // 등록
  const submitMsg = {
    open: true,
    text: '작성하신 내용으로 등록하시겠습니까?',
    cancel: true,
    onClick: () => submitForm(),
  };

  const doneMsg = {
    open: true,
    text: '전송이 완료되었습니다.',
    onClick: () => {
      window.location.href = '/admin/notice';
    },
  };

  const errerMsg = {
    open: true,
    text: '오류가 발생했습니다\n다시 시도해 주세요.',
  };

  const submitForm = async () => {
    if (values) {
      setLoading(true);
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info] = await adminApi.noticeApi.addNotice(values);
      if (info) {
        setLoading(false);
        setModal(doneMsg);
      } else {
        setLoading(false);
        setModal(errerMsg);
      }
    }
  };

  // 수정

  const modifyMsg = {
    open: true,
    text: '작성하신 내용으로 수정하시겠습니까?',
    cancel: true,
    onClick: () => modifyNotice(),
  };

  const modifyNotice = async () => {
    if (values) {
      setLoading(true);
      const noticeId = values.id;
      const params = {
        title: values.title,
        content: values.content,
        fileUrl: values.fileUrl,
        fileName: values.fileName,
        isFixed: values.isFixed,
      };
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info] = await adminApi.noticeApi.modifyNotice(noticeId, params);
      if (info) {
        setLoading(false);
        setModal(doneMsg);
      } else {
        setLoading(false);
        setModal(errerMsg);
      }
    }
  };

  // 삭제

  const delMsg = {
    open: true,
    text: '삭제하시겠습니까?',
    cancel: true,
    onClick: () => delItem(id),
  };

  const del = () => {
    setModal(delMsg);
  };

  const delItem = async (id) => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.noticeApi.deleteNotice(id);
    if (info) {
      setModal({
        open: true,
        text: '삭제가 완료되었습니다.',
        onClick: () => {
          window.location.href = '/admin/notice';
        },
      });
    } else {
      setModal(errerMsg);
    }
  };

  // 파일 업로드
  const fileUpload = (event) => {
    event.preventDefault();
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      reader.onloadend = async () => {
        if (!loading) {
          setLoading(true);
          const fd = new FormData();
          fd.append('file', file);
          if (fileUpload) {
            const [info] = await adminApi.noticeApi.fileUpload(fd);
            if (info) {
              setLoading(false);
              setModal({
                open: true,
                text: '전송이 완료되었습니다.',
              });
              setValues({
                ...values,
                fileName: info.result.fileName,
                fileUrl: info.result.fileUrl,
              });
              event.target.value = '';
            } else {
              setLoading(false);
              setModal({
                open: true,
                text: '오류가 발생했습니다\n다시 시도해 주세요.',
              });
              event.target.value = '';
            }
          }
        }
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <AdminAlert modal={modal} setModal={setModal} />
      <NoticeDetailPresenter
        values={values}
        onChange={onChange}
        checkBoxChange={checkBoxChange}
        navigate={navigate}
        submit={submit}
        del={del}
        fileUpload={fileUpload}
      />
    </>
  );
}

export default NoticeDetailContainer;
