import React, { useState } from 'react';
import { RowDiv, Div, Span, Absolute } from 'styles/CommonCSS';
import NoHeader from 'Layout/NoHeader';
import { ReactComponent as LogoIcon } from 'assets/icon/logo.svg';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import Input from 'components/Input/Input';
import Button from 'components/Element/Button';
import CheckBox from 'components/Input/CheckBox';
import Agree from 'components/Element/Modal/Agree';

function LoginPresenter({
  values,
  onChange,
  checkBoxChange,
  phoneActive,
  codeActive,
  isRunning,
  minutes,
  seconds,
  sendCode,
  checkCode,
  invalids,
  setInvalids,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const agree1 = {
    open: true,
    title: '개인정보취급방침',
    text: '개인정보취급방침',
  };

  const agree2 = {
    open: true,
    title: '서비스이용약관',
    text: '서비스이용약관',
  };
  return (
    <>
      <Agree isOpen={isOpen} setIsOpen={setIsOpen} />
      <NoHeader>
        <LogoIcon />
        <RowDiv gap={20} mt={80} mb={40}>
          <RowDiv align="center">
            <CheckBox
              id="agree1"
              name="agree1"
              checked={values.agree1}
              value={values.agree1}
              onChange={checkBoxChange}
            />
            <Div pointer>
              <Text>
                <Span underline onClick={() => setIsOpen(agree1)}>
                  개인정보취급방침
                </Span>
                <Label htmlFor="agree1"> &nbsp;동의</Label>
              </Text>
            </Div>
          </RowDiv>
          <RowDiv align="center">
            <CheckBox
              id="agree2"
              name="agree2"
              checked={values.agree2}
              value={values.agree2}
              onChange={checkBoxChange}
            />
            <Div pointer>
              <Text>
                <Span underline onClick={() => setIsOpen(agree2)}>
                  서비스이용약관
                </Span>
                <Label htmlFor="agree2"> &nbsp;동의</Label>
              </Text>
            </Div>
          </RowDiv>
        </RowDiv>
        <RowDiv gap={6}>
          <Div position="relative">
            <Input
              readOnly={!values.agree1 || !values.agree2}
              placeholder="휴대폰번호"
              name="phone"
              value={values.phone}
              onChange={onChange}
              invalids={invalids}
              setInvalids={setInvalids}
            />
            {(invalids.phone.invalid === true ||
              invalids.phone.invalid === 'success') && (
              <TextP color="warningColor" size={12} pl={5}>
                {invalids.phone.text}
              </TextP>
            )}
            {isRunning && (
              <Absolute top="13px" mTop="14px" right="12px">
                <Text color="warningColor">
                  {`0${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
                </Text>
              </Absolute>
            )}
          </Div>
          <Button
            text="인증번호 요청"
            color="white"
            onClick={sendCode}
            active={phoneActive}
          />
        </RowDiv>
        <RowDiv gap={6} mt={20}>
          <Div position="relative">
            <Input
              readOnly={invalids.phone.invalid !== 'success'}
              placeholder="인증번호 입력"
              name="code"
              value={values.code}
              onChange={onChange}
            />
            {invalids.code.invalid === true && (
              <TextP color="warningColor" size={12} pl={5}>
                {invalids.code.text}
              </TextP>
            )}
          </Div>
          <Button
            text="인증번호 확인"
            color="white"
            onClick={checkCode}
            active={codeActive}
          />
        </RowDiv>
      </NoHeader>
    </>
  );
}

const Label = styled.label`
  cursor: pointer;
`;

const TextP = styled(Text)`
  width: 300px;
  position: absolute;
  bottom: -17px;
`;
export default LoginPresenter;
