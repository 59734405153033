import ListIcon from 'assets/icon/list.svg';
import RankIcon from 'assets/icon/rank.svg';
import OfficeIcon from 'assets/icon/office.svg';
import MypageIcon from 'assets/icon/mypage.svg';
import second from 'assets/graphic/main/second.svg';
import third from 'assets/graphic/main/third.svg';
import fourth from 'assets/graphic/main/fourth.svg';
import fifth from 'assets/graphic/main/fifth.svg';
import first from 'assets/graphic/main/first.svg';

export const rankList = [first, second, third, fourth, fifth];
export const menuList = [
  {
    title: '챌린지 리스트',
    icon: ListIcon,
    bg: 'bgColorB9',
    width: '24.66%',
    pathname: '/challenge',
  },
  {
    title: '챌린지 랭킹',
    icon: RankIcon,
    bg: 'bgColorF3',
    width: '23.29%',
    pathname: '/ranking',
  },
  {
    title: '입주사 소개',
    icon: OfficeIcon,
    bg: 'warningColor',
    width: '31.51%',
    padding: '11% 0',
    pathname: '/company',
  },
  {
    title: '마이페이지',
    icon: MypageIcon,
    bg: 'main',
    width: '27.4%',
    padding: '9% 0',
    pathname: '/profile',
  },
];
