import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { accountApi } from 'api';
import Loading from 'components/Element/Loading';
import { isEmptyObjItems } from 'modules/checkData';
import Alert from 'components/Element/Modal/Alert';
import ProfilePresenter from './ProfilePresenter';

function ProfileContainer(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'nickname']);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [list, setList] = useState();
  const [values, setValues] = useState();
  const [alert, setAlert] = useState();
  const [filters, setFilters] = useState({
    perPage: 10,
    page: 1,
    lastRowNum: 0,
  });

  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
  });
  const [profile, setProfile] = useState();

  const getList = async () => {
    setLoading(true);
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await accountApi.profile(filters);
    if (info) {
      const { result } = info;

      setPages({
        currentCount: result.currentCount,
        currentPage: result.currentPage,
        perPage: result.perPage,
        totalPages: result.totalPages,
        totalCount: result.totalCount,
      });
      setProfile({
        nickname: result.account.nickname,
        companyId: result.account.company_id,
      });
      setValues({
        nickname: result.account.nickname,
        companyId: String(result.account.company_id),
        company: result.account.company,
      });
      setList(result.list);
      setLoading(false);
    }
  };

  // 회사리스트 api
  const [companyList, setCompanyList] = useState();
  const company = async () => {
    const [info] = await accountApi.companyList();
    if (info) {
      setCompanyList(info.result);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    company();
    return () => setLoading(true);
  }, []);

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, [filters]);

  const onChange = (value, name, id) => {
    setValues({ ...values, [name]: value });
    if (name === 'company') {
      setValues({ ...values, company: value, companyId: String(id) });
    }
  };

  useEffect(() => {
    if (values) {
      setActive(!isEmptyObjItems(values));
    }
  }, [values]);

  const warning = {
    open: true,
    text: '수정할 내용이 없습니다.',
  };

  const error = {
    open: true,
    text: '오류가 발생했습니다.\n다시 시도해 주세요',
  };

  const complete = {
    open: true,
    text: '수정이 완료되었습니다.',
    onClick: () => {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      setCookie('nickname', values.nickname, { path: '/', expires });
      window.location.reload();
    },
  };

  const submit = async () => {
    if (
      profile.nickname === values.nickname &&
      String(profile.companyId) === String(values.companyId)
    ) {
      setAlert(warning);
    } else {
      const params = { ...values };
      delete params.id;
      delete params.company;
      axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
      const [info] = await accountApi.modify(params);
      if (info) {
        setAlert(complete);
      } else {
        setAlert(error);
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Alert modal={alert} setModal={setAlert} />
      <ProfilePresenter
        list={list}
        filters={filters}
        setFilters={setFilters}
        pages={pages}
        values={values}
        onChange={onChange}
        active={active}
        submit={submit}
        companyList={companyList}
      />
    </>
  );
}

export default ProfileContainer;
