import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RowDiv } from 'styles/CommonCSS';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import Text from 'components/Element/Text';
import styled from 'styled-components';

function SimpleSlider({ fixedNotices, goDetail }) {
  const settings = {
    dots: 'false',
    infinite: 'true',
    speed: 1000,
    slidestoshow: 1,
    slidestoscroll: 1,
    autoplay: !(fixedNotices && fixedNotices.length === 1),
    arrows: 'false',
    vertical: 'true',
    autoplayspeed: 3000,
  };

  return (
    <StyleSlider {...settings}>
      {fixedNotices?.map((item, index) => (
        <RowDiv key={`item${index}`} width="100%" height={42} padding="0 10px">
          <RowDiv
            justify="space-between"
            align="center"
            onClick={() => goDetail(item.id)}
            height="100%"
            pointer
          >
            <Text weight={700} line={1} width="75%">
              {item.title}
            </Text>
            <Row gap={6} align="center">
              <Text color="main" weight={700}>
                바로가기
              </Text>
              <ArrowIcon />
            </Row>
          </RowDiv>
        </RowDiv>
      ))}
    </StyleSlider>
  );
}

const Row = styled(RowDiv)`
  & > svg > path {
    fill: ${({ theme }) => theme.colors.main};
  }
`;

const StyleSlider = styled(Slider)`
  height: 100%;
`;

export default SimpleSlider;

// height: auto !important;
