import React, { useState, useEffect } from 'react';
import { challengeApi } from 'api';
import Loading from 'components/Element/Loading';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import QRScanner from 'components/Common/QRScanner';
import ChallengeDetailPresenter from './ChallengeDetailPresenter';

function ChallengeDetailContainer(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const [item, setItem] = useState();
  const [qrOpen, setQrOpen] = useState(false);
  const [toastText, setToastText] = useState();

  const getList = async () => {
    setLoading(true);
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await challengeApi.challengeDetail(id);
    if (info) {
      setItem(info.result);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, []);

  const checkImage = async (image) => {
    const [info, error] = await challengeApi.challengeCode(id, { image });
    if (info) {
      setToastText(
        <>
          업로드가 완료되었습니다.
          <br />
          포인트는 관리자 승인 후, 지급됩니다.
        </>,
      );
      setLoading(false);
    } else if (error?.resCode === 409) {
      setToastText(<>이미 완료한 챌린지입니다.</>);
      setLoading(false);
    } else {
      setToastText(
        <>
          오류가 발생했습니다.
          <br />
          다시 시도해 주세요.
        </>,
      );
      setLoading(false);
    }
  };

  const fileUpload = (event) => {
    event.preventDefault();
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size >= maxSize) {
        event.target.value = '';
        setToastText(
          <>
            용량이 초과되었습니다.
            <br />
            10MB 이하 이미지를 첨부해주세요.
          </>,
        );
      } else {
        reader.onloadend = async () => {
          if (!loading) {
            setLoading(true);
            const fd = new FormData();
            fd.append('file', file);
            if (fileUpload) {
              const [info] = await challengeApi.fileUpload(fd);
              if (info) {
                setItem({
                  ...item,
                  fileName: info.result.fileName,
                  fileUrl: info.result.fileUrl,
                });
                checkImage(info.result.fileUrl);
                event.target.value = '';
              } else {
                setToastText(
                  <>
                    오류가 발생했습니다.
                    <br />
                    다시 시도해 주세요.
                  </>,
                );
                setLoading(false);
                event.target.value = '';
              }
            }
          }
        };
        if (file) reader.readAsDataURL(file);
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <QRScanner modalOpen={qrOpen} setModalOpen={setQrOpen} />
      <ChallengeDetailPresenter
        item={item}
        fileUpload={fileUpload}
        toastText={toastText}
        setToastText={setToastText}
        setQrOpen={setQrOpen}
      />
    </>
  );
}

export default ChallengeDetailContainer;
