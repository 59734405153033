import React from 'react';
import { ColDiv, Div } from 'styles/CommonCSS';
import NoHeader from 'Layout/NoHeader';
import OpacityDiv from 'components/Common/OpacityDiv';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function Detail({ children, ...props }) {
  const navigate = useNavigate();
  return (
    <NoHeader>
      <ColDiv
        width="fit-content"
        padding="10px 20px"
        position="absolute"
        top="0"
        left="0"
        pointer
        onClick={() => navigate(-1)}
      >
        <OpacityDiv width={26} ratio="1/1" bg="colorCF" radius="50%">
          <ArrowButton direction="left" />
        </OpacityDiv>
      </ColDiv>
      <Div
        {...props}
        width="100%"
        // height="calc(100vh - 86px)"
        position="relative"
      >
        {children}
      </Div>
    </NoHeader>
  );
}

const ArrowButton = styled(ArrowIcon)`
  width: 7px;
  height: 13px;
  padding: 0 10px 0 9p;
  cursor: pointer;
  transform: ${({ direction }) =>
    direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export default Detail;
