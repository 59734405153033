import React, { useState, useEffect } from 'react';
import AdminAlert from 'components/Element/Modal/AdminAlert';
import { adminApi } from 'api';
import Loading from 'components/Element/Loading';
import axios from 'axios';
import AdminForm from 'components/Routes/Admin/AdminForm';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import fileDownloader from 'modules/fileDownloader';
import ChallengeListPresenter from './ChallengeListPresenter';

function ChallengeListContainer(props) {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [values, setValues] = useState({});
  const [filter, setFilter] = useState({
    year: moment().format('YYYY'),
    month: `${moment().format('MM').replace(/0/gi, '')}월`,
    monthId: moment().format('MM'),
  });
  const adminToken = sessionStorage.getItem('adminToken');
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();

  // 필터 온체인지

  const filterOnChange = (value, name, id) => {
    let tempFilter;
    if (id) {
      tempFilter = { monthId: String(id) };
    }
    setFilter({ ...filter, ...tempFilter, [name]: value });
  };

  // 등록 온체인지
  const radioChange = (event) => {
    const { value, name } = event.target;
    if (name === 'type' && value === 'image') {
      setValues({ ...values, [name]: value, codes: '' });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const onChange = (value, name, id, file) => {
    setValues({ ...values, [name]: value });
    if (id) {
      setValues({ ...values, [name]: value, monthId: String(id) });
    }
    if (file) {
      setValues({ ...values });
    }
  };

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.challengeApi.challengeList(
      filter.year,
      filter.monthId,
    );
    if (info) {
      const { result } = info;
      setList(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(false);
  }, [filter]);

  const onClickHandler = () => {
    if (modalOpen === 'add') {
      setModal(submitMsg);
      submitForm();
    } else {
      setModal(modifyMsg);
      modify();
    }
  };

  const doneMsg = {
    open: true,
    text: '전송이 완료되었습니다.',
    onClick: () => {
      window.location.href = '/admin/challenge';
    },
  };

  const errerMsg = {
    open: true,
    text: '오류가 발생했습니다\n다시 시도해 주세요.',
  };

  // 등록

  const submitMsg = {
    open: true,
    text: '작성하신 내용으로 등록하시겠습니까?',
    cancel: true,
    onClick: () => submitForm(),
  };

  const submit = () => {
    setModal(submitMsg);
  };

  const submitForm = async () => {
    const params = {
      title: values.title,
      type: values.type,
      year: values.year,
      month: values.monthId,
      point: values.point,
      codes: values.codes,
      description: values.description,
      fileUrl: values.fileUrl,
      isDup: values.isDup,
    };
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.challengeApi.addChallenge(params);
    if (info) {
      setModal(doneMsg);
    } else {
      setModal(errerMsg);
    }
  };

  // 수정

  const modifyMsg = {
    open: true,
    text: '작성하신 내용으로 수정하시겠습니까?',
    cancel: true,
    onClick: () => modify(),
  };

  const modify = async () => {
    if (values) {
      const challengeId = values.id;
      const params = {
        title: values.title,
        description: values.description,
        fileUrl: values.fileUrl,
      };
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info] = await adminApi.challengeApi.modifyChallenge(
        challengeId,
        params,
      );
      if (info) {
        setModal(doneMsg);
      } else {
        setModal(errerMsg);
      }
    }
  };

  // 삭제

  let challengeId;
  const delMsg = {
    open: true,
    text: '삭제하시겠습니까?',
    cancel: true,
    onClick: () => delItem(),
  };

  const del = (id) => {
    setModal(delMsg);
    challengeId = id;
  };

  const delItem = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    const [info] = await adminApi.challengeApi.deleteChallenge(challengeId);
    if (info) {
      setModal({
        open: true,
        text: '삭제가 완료되었습니다.',
        onClick: () => {
          window.location.href = '/admin/challenge';
        },
      });
    } else {
      setModal(errerMsg);
    }
  };

  // 이미지 업로드

  const fileUpload = async (fd) => {
    if (!loading) {
      axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
      const [info, error] = await adminApi.challengeApi.fileUpload(fd);
      if (info) {
        setModal({
          open: true,
          text: '이미지가 등록되었습니다.',
        });
        setValues({ ...values, fileUrl: info.result.fileUrl });
      }
      // else if (error.resCode === ) {
      //   setLoading(false);
      //   setModal({
      //     open: true,
      //     text: '',
      //   });
      // }
    }
  };

  const excelDownload = async () => {
    setLoading(true);
    const [info] = await adminApi.challengeApi.downloadExcel();
    if (info) {
      fileDownloader(info);
      setLoading(false);
    }
  };

  const monthList = [
    { name: '1월', id: '01' },
    { name: '2월', id: '02' },
    { name: '3월', id: '03' },
    { name: '4월', id: '04' },
    { name: '5월', id: '05' },
    { name: '6월', id: '06' },
    { name: '7월', id: '07' },
    { name: '8월', id: '08' },
    { name: '9월', id: '09' },
    { name: '10월', id: '10' },
    { name: '11월', id: '11' },
    { name: '12월', id: '12' },
  ];

  const goDetail = (item) => {
    if (item.id) {
      navigate(`${String(item.id)}`, { state: { item } });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <AdminAlert modal={modal} setModal={setModal} />
      <AdminForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        monthList={monthList}
        values={values}
        setValues={setValues}
        onChange={onChange}
        radioChange={radioChange}
        onClickHandler={onClickHandler}
        fileUpload={fileUpload}
      />
      <ChallengeListPresenter
        list={list}
        monthList={monthList}
        setModalOpen={setModalOpen}
        del={del}
        submit={submit}
        filter={filter}
        filterOnChange={filterOnChange}
        goDetail={goDetail}
        excelDownload={excelDownload}
      />
    </>
  );
}

export default ChallengeListContainer;
