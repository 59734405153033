import React from 'react';
import { Div, ColDiv, RowDiv } from 'styles/CommonCSS';
import Default from 'Layout/Default';
import Text from 'components/Element/Text';
import styled from 'styled-components';
import hexToRGB from 'modules/hexToRGB';

function CompanyPresenter({ list, setModalOpen, filter, setFilter }) {
  return (
    <Default>
      <Div width="100%">
        <Text weight={700} size={20} mt={20} mb={20}>
          입주사 소개
        </Text>
      </Div>
      <RowDiv
        width="calc(100% + 60px)"
        ml="0"
        height={34}
        padding="0 30px"
        style={{ borderBottom: `1px solid ${hexToRGB('#CFCFCF', 0.26)}` }}
      >
        <RowDiv
          align="center"
          justify="center"
          radius="8px 8px 0 0"
          cardBorder
          flex={1}
          onClick={() => setFilter()}
          bgColor={!filter ? 'white' : hexToRGB('#CFCFCF', 0.16)}
        >
          <Text
            size={14}
            weight={!filter ? 700 : 400}
            color={!filter ? 'main' : 'colorCF'}
          >
            전체
          </Text>
        </RowDiv>
        <RowDiv
          align="center"
          justify="center"
          radius="8px 8px 0 0"
          cardBorder
          flex={1}
          onClick={() => setFilter('단체')}
          bgColor={filter === '단체' ? 'white' : hexToRGB('#CFCFCF', 0.16)}
        >
          <Text
            size={14}
            weight={filter === '단체' ? 700 : 400}
            color={filter === '단체' ? 'main' : 'colorCF'}
          >
            협·단체
          </Text>
        </RowDiv>
        <RowDiv
          align="center"
          justify="center"
          radius="8px 8px 0 0"
          cardBorder
          flex={1}
          bgColor={filter === '스타트업' ? 'white' : hexToRGB('#CFCFCF', 0.16)}
          onClick={() => setFilter('스타트업')}
        >
          <Text
            size={14}
            weight={filter === '스타트업' ? 700 : 400}
            color={filter === '스타트업' ? 'main' : 'colorCF'}
          >
            스타트업
          </Text>
        </RowDiv>
      </RowDiv>
      <ColDiv gap={10} width="100%" pt={20}>
        {list.map((item, index) => (
          <Div
            key={`item${index}`}
            padding="18px 20px 10px"
            onClick={() => setModalOpen(item)}
            pointer
            radius="6px"
            justify="flex-start"
            cardBorder
          >
            <Img src={item.logo} />
            <Text mt={14} mb={6}>
              {item.name}
            </Text>
            <Text color="subFont">{item.sub_name}</Text>
          </Div>
        ))}
      </ColDiv>
    </Default>
  );
}

const Img = styled.img`
  height: 22px;
  object-fit: contain;
`;

export default CompanyPresenter;
