import React from 'react';
import styled from 'styled-components';
import { ColDiv } from 'styles/CommonCSS';

function BgDiv({ children, ratio, ...props }) {
  return (
    <Wrap {...props} ratio={ratio}>
      {children}
    </Wrap>
  );
}
const Wrap = styled(ColDiv)`
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '11px')};
  color: ${({ theme, color }) => color || theme.colors.font};
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors[border]}` : 'unset'};
  background-color: ${({ bgColor }) => bgColor || null};
  align-items: center;
  justify-content: center;
  aspect-ratio: ${({ ratio }) => ratio || null};
`;
export default BgDiv;
