const routes = [
  {
    label: '챌린지 리스트',
    pathname: '/challenge',
  },
  {
    label: '챌린지 랭킹',
    pathname: '/ranking',
  },
  {
    label: '입주사 소개',
    pathname: '/company',
  },
  {
    label: '마이페이지',
    pathname: '/profile',
  },
];
export default routes;
