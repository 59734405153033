import React from 'react';
import { RowDiv, Div, Span, GridDiv, ColDiv } from 'styles/CommonCSS';
import Default from 'Layout/Default';
import styled, { css } from 'styled-components';
import Text from 'components/Element/Text';
import BorderCard from 'components/Common/BorderCard';
import BgDiv from 'components/Common/BgDiv';
import hexToRGB from 'modules/hexToRGB';
import ImageComponent from 'components/Element/Image';
import GrayBackground from 'components/Element/GrayBackground';
import ServerButtonPagenation from 'components/Element/ServerButtonPagenation';
import { rankList, menuList } from 'data/Main/mainList';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/Element/Loading';
import SimpleSlider from 'components/Element/SimpleSlider';

function MainPresenter({
  info,
  list,
  pages,
  filters,
  setFilters,
  goDetail,
  nickName,
  noticeLoading,
  fixedNotices,
}) {
  const navigate = useNavigate();

  return (
    <Default>
      <Div mt={20} width="100%">
        <Div>
          <Text size={20} weight={700}>
            안녕하세요,
          </Text>
          <Text size={20} weight={700} mt={4}>
            <Span color="main">{info?.nickname}</Span> 님
          </Text>
        </Div>
        <GridDiv display="grid" grid={2} colGap={19} mt={10}>
          <BorderCard padding="10px">
            <Text weight={700} textAlign="center">
              챌린지 포인트
            </Text>
            <Text size={17} textAlign="center" mt={6}>
              <Span color="rankColor" weight={700}>
                {info?.myPoint}
              </Span>{' '}
              P
            </Text>
          </BorderCard>
          <BorderCard padding="10px">
            <Text weight={700} textAlign="center">
              챌린지 등수
            </Text>
            <Text size={17} textAlign="center" mt={6}>
              <Span color="rankColor" weight={700}>
                {info?.myRank}
              </Span>{' '}
              위
            </Text>
          </BorderCard>
        </GridDiv>
        <BorderCard margin="10px 0 30px" height={42}>
          <SimpleSlider fixedNotices={fixedNotices} goDetail={goDetail} />
        </BorderCard>

        {/*  <Text size={17} weight={700}>   */}
        {/*  순위 별 상품 안내  */}
        {/*  </Text>  */}
        {/*  <GridDiv  */}
        {/*  display="grid"  */}
        {/*  grid={3}  */}
        {/*  colGap={15}  */}
        {/*  rowGap={18}  */}
        {/*  margin="10px 0 30px"  */}
        {/*  >  */}
        {/*  {rankList.map((rank, idx) => (  */}
        {/*    <Div key={`rank${idx}`}>  */}
        {/*      <Img src={rank} />  */}
        {/*    </Div>  */}
        {/*  ))}  */}
        {/*  </GridDiv>  */}
        <Text size={17} weight={700} mt={30}>
          주요 메뉴
        </Text>
        <GridDiv
          display="grid"
          grid={2}
          colGap={19}
          rowGap={19}
          mt={10}
          mb={30}
        >
          {menuList.map((list, index) => (
            <BorderCard
              key={`list${index}`}
              ratio="9/7"
              align="center"
              justify="space-evenly"
              onClick={() =>
                setTimeout(() => {
                  navigate(list.pathname);
                }, [600])
              }
              Hover
              pointer
            >
              <GraphicCircle
                align="center"
                justify="center"
                bg={list.bg}
                padding={list.padding ? list.padding : '7% 0'}
              >
                <ImageComponent src={list.icon} width={list.width} />
              </GraphicCircle>
              <Text weight={700}>{list.title}</Text>
            </BorderCard>
          ))}
        </GridDiv>
        <Text size={17} weight={700}>
          공지사항
        </Text>
        <GrayBackground height={30} align="center" mt={10}>
          <Div flex={7} padding="0 10px">
            <Text weight={700} textAlign="center">
              제목
            </Text>
          </Div>
          <Div flex={3}>
            <Text weight={700} textAlign="center">
              등록일
            </Text>
          </Div>
        </GrayBackground>
        <Div position="relative" minHeight={150}>
          {noticeLoading ? (
            <Loading type="inside" />
          ) : (
            list?.map((notice) => (
              <RowDiv
                height={30}
                align="center"
                key={notice.id}
                onClick={() => goDetail(notice.id)}
                pointer
              >
                <Div flex={7} padding="0 10px" overflowX="hidden">
                  <Text line={1}>{notice.title}</Text>
                </Div>
                <Div flex={3}>
                  <Text textAlign="center">
                    {moment(notice.created_dt).format('YYYY-MM-DD')}
                  </Text>
                </Div>
              </RowDiv>
            ))
          )}
        </Div>
        <ServerButtonPagenation
          filters={filters}
          setFilters={setFilters}
          pages={pages}
        />
      </Div>
    </Default>
  );
}

const GraphicCircle = styled(ColDiv)`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 45.21%;
    aspect-ratio: 1 / 1;
    background-color: ${({ theme, bg }) =>
      bg ? hexToRGB(theme.colors[bg], 0.16) : ''};
    z-index: -1;
  }
`;

const Img = styled.img`
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
  width: 100%;
`;

export default MainPresenter;
