import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { mainApi } from 'api';
import { Div, RowDiv } from 'styles/CommonCSS';
import OpacityDiv from 'components/Common/OpacityDiv';
import moment from 'moment-timezone';
import Detail from 'Layout/Detail';
import Text from 'components/Element/Text';
import Loading from 'components/Element/Loading';
import Viewer from 'components/Editor/Viewer';

function NoticeDetail(props) {
  const [cookies] = useCookies(['token']);
  const { state } = useLocation();
  const { id } = state;
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);

  const getList = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.token}`;
    const [info] = await mainApi.noticeDetail(id);
    if (info) {
      setItem(info.result);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    return () => setLoading(true);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Detail>
      <Div mt={20} width="100%">
        <OpacityDiv
          bg="main"
          padding="10px"
          align="flex-start"
          borderBottom={1}
        >
          <Div height={44}>
            <Text size={14} weight={700}>
              {item.title}
            </Text>
          </Div>
          <RowDiv gap={6} mt={10}>
            <Text>등록일</Text>
            <Text>{moment(item.created_dt).format('YYYY-MM-DD')}</Text>
          </RowDiv>
        </OpacityDiv>
        <Div padding="10px 20px 10px 10px">
          <Viewer value={item.content} />
        </Div>
        <OpacityDiv
          bg="colorCF"
          padding="20px 10px"
          align="flex-start"
          borderTop={1}
        >
          <RowDiv gap={6}>
            <Text>첨부파일</Text>
            <a
              href={item.attachment_file}
              target="_blank"
              rel="noreferrer"
              download
            >
              <Text underline color="#1A98F3">
                {item.attachment_file_name}
              </Text>
            </a>
          </RowDiv>
        </OpacityDiv>
      </Div>
    </Detail>
  );
}

export default NoticeDetail;
