import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Div, fadein, flexCol, RowDiv } from 'styles/CommonCSS';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import Button from 'components/Element/Button';
import Text from 'components/Element/Text';

function BasicModal({
  modalOpen,
  setModalOpen,
  children,
  type,
  onClickHandler,
  noBottomButton,
}) {
  const [currentScrollTop, setCurrentScrollTop] = useState();

  useEffect(() => {
    if (modalOpen) {
      setCurrentScrollTop(window.scrollY);
      document.body.style.cssText = `
          position: fixed;
          top: -${window.scrollY}px;
          overflow-y: overlay;
          max-width: unset;
          width:100%`;
    } else {
      document.body.style.cssText = `max-width:unset;`;
      window.scrollTo(0, currentScrollTop);
    }

    return () => {
      document.body.style.cssText = '';
    };
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const onClickModal = () => {
    if (modalOpen.onClick) {
      modalOpen.onClick();
    }
    setModalOpen(false);
  };

  return modalOpen ? (
    <Wrap open={modalOpen}>
      <Content width="100%" type={type}>
        <Row
          width="100%"
          justify={type === 'user' ? 'space-between' : 'flex-end'}
          zIndex={3}
          mb={type === 'user' ? '10px' : '20px'}
          align="center"
          padding={type === 'admin' && '0 20px'}
        >
          {type === 'user' && (
            <Text weight={700} size={17}>
              기업 상세 정보
            </Text>
          )}
          <Close onClick={closeModal} />
        </Row>
        <Div open={modalOpen} type={type} width="100%">
          {children}
        </Div>
        {!noBottomButton &&
          (type === 'user' ? (
            <Button
              text="확인"
              bgColor="main"
              color="white"
              onClick={onClickModal}
              active
            />
          ) : (
            <RowDiv gap={10}>
              <Button
                width={120}
                height={40}
                text="취소"
                onClick={onClickModal}
              />
              <Button
                width={120}
                height={40}
                text="확인"
                active
                onClick={onClickHandler}
              />
            </RowDiv>
          ))}
      </Content>
    </Wrap>
  ) : (
    <></>
  );
}

const Wrap = styled.div`
  ${flexCol('center', 'center')}
  ${(props) =>
    props.open &&
    css`
      ${fadein()}
    `};
  z-index: 8000;
  position: fixed;
  height: 100%;
  width: 100%;
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  padding: 0 20px;
  background-color: rgba(30, 30, 32, 0.46);

  @media ${({ theme }) => theme.device.mobile} {
  }
`;

const Content = styled.div`
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  ${flexCol('center', 'space-between')};
`;

const Close = styled(CloseIcon)`
  cursor: pointer;
`;

const Row = styled(RowDiv)`
  & > svg > path {
    fill: ${({ theme }) => theme.colors.subFont};
  }
`;

export default BasicModal;
