import React from 'react';
import { Span, ColDiv, RowDiv, Div } from 'styles/CommonCSS';
import Default from 'Layout/Default';
import styled from 'styled-components';
import Text from 'components/Element/Text';
import OpacityDiv from 'components/Common/OpacityDiv';
import moment from 'moment-timezone';
import { ReactComponent as ContentsIcon } from 'assets/icon/contents.svg';
import Loading from 'components/Element/Loading';

function ChallengeListPresenter({
  monthList,
  list,
  onClick,
  goDetail,
  filter,
  monthLoading,
}) {
  return (
    <Default>
      <Div width="100%">
        <Text weight={700} size={20} mt={20} mb={20}>
          챌린지 리스트
        </Text>
      </Div>
      <Scroll pb={13} gap={10}>
        {monthList?.map((list, index) => (
          <OpacityDiv
            key={`month${index}`}
            opBorder="colorCF"
            bg="colorCF"
            radius="50%"
            width={55}
            minWidth={55}
            ratio="1/1"
            onClick={() => onClick(list.yearMonth)}
            selected={filter === list.yearMonth}
            pointer
          >
            <Text weight={700} size={14} color="subFont">
              {list.month}
            </Text>
          </OpacityDiv>
        ))}
      </Scroll>
      <ColDiv
        gap={10}
        width="100%"
        position="relative"
        minHeight="calc(100vh - 220px)"
      >
        {monthLoading ? (
          <Loading type="inside" />
        ) : (
          <>
            {list?.map((item, idx) => (
              <OpacityDiv
                key={`item${idx}`}
                padding="10px"
                gap={6}
                onClick={() => goDetail(item.id)}
                bg={item.is_possible_challenge === 0 && 'colorCF'}
                opBorder="colorCF"
                align="flex-start"
                radius={6}
                disabled={item.is_possible_challenge === 0}
                pointer
              >
                <Text
                  weight={700}
                  size={12}
                  color={item.is_possible_challenge === 0 ? 'subFont' : null}
                >
                  {item.title}
                </Text>
                <Text
                  size={12}
                  color={item.is_possible_challenge === 0 ? 'subFont' : null}
                >
                  <Span
                    weight={700}
                    color={
                      item.is_possible_challenge === 0 ? 'subFont' : 'main'
                    }
                  >
                    {item.point}
                  </Span>
                  &nbsp;P
                </Text>
                <Text
                  size={10}
                  color={item.is_possible_challenge === 0 ? 'subFont' : null}
                >
                  {moment(item.start_dt).format('YYYY-MM-DD')}&nbsp;~&nbsp;
                  {moment(item.end_dt).format('YYYY-MM-DD')}
                </Text>
              </OpacityDiv>
            ))}
            {list.length === 0 && (
              <ColDiv
                height="calc(100vh - 264px)"
                align="center"
                justify="center"
                gap={20}
              >
                <ContentsIcon />
                <Text weight={700} size={12}>
                  챌린지 준비중입니다
                </Text>
              </ColDiv>
            )}
          </>
        )}
      </ColDiv>
    </Default>
  );
}

const Scroll = styled(RowDiv)`
  width: 100%;
  overflow-x: overlay;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default ChallengeListPresenter;
