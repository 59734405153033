import React from 'react';
import { RowDiv, Div, ColDiv } from 'styles/CommonCSS';
import Default from 'Layout/Default';
import Text from 'components/Element/Text';
import GrayBackground from 'components/Element/GrayBackground';
import OpacityDiv from 'components/Common/OpacityDiv';
import ServerButtonPagenation from 'components/Element/ServerButtonPagenation';
import Input from 'components/Input/Input';
import Select from 'components/Input/Select';
import useSelectOpen from 'hooks/useSelectOpen';
import Button from 'components/Element/Button';

function ProfilePresenter({
  list,
  filters,
  setFilters,
  pages,
  values,
  onChange,
  active,
  submit,
  companyList,
}) {
  const { isOpen, setIsOpen } = useSelectOpen();

  return (
    <Default>
      <Div width="100%" margin="20px 0">
        <Text weight={700} size={20}>
          마이페이지
        </Text>
      </Div>
      <ColDiv width="100%" gap={10}>
        <Text weight={700} size={17}>
          챌린지 내역
        </Text>
        <GrayBackground height={30} align="center" mt={10} width="100%">
          <Div flex={7} padding="0 10px">
            <Text textAlign="center">제목</Text>
          </Div>
          <Div flex={3}>
            <Text textAlign="center">상태</Text>
          </Div>
        </GrayBackground>
        {list?.map((item, index) => (
          <RowDiv height={30} width="100%" align="center" key={`item${index}`}>
            <Div flex={7} padding="0 10px">
              <Text line={1}>{item.title}</Text>
            </Div>
            <ColDiv flex={3} align="center" justify="center">
              <OpacityDiv
                opBorder={
                  item.is_pass === null
                    ? 'subFont'
                    : item.is_pass === 1
                    ? 'main'
                    : 'warningColor'
                }
                bg={
                  item.is_pass === null
                    ? 'subFont'
                    : item.is_pass === 1
                    ? 'main'
                    : 'warningColor'
                }
                radius="11px"
                width={55}
                ratio="2/1"
                height={22}
                pointer
              >
                <Text
                  weight={700}
                  size={12}
                  color={
                    item.is_pass === null
                      ? 'subFont'
                      : item.is_pass === 1
                      ? 'main'
                      : 'warningColor'
                  }
                >
                  {item.is_pass === null
                    ? '대기'
                    : item.is_pass === 1
                    ? '승인'
                    : '거절'}
                </Text>
              </OpacityDiv>
            </ColDiv>
          </RowDiv>
        ))}
        <ServerButtonPagenation
          filters={filters}
          setFilters={setFilters}
          pages={pages}
        />
      </ColDiv>
      <ColDiv mt={30} width="100%" gap={20}>
        <Text weight={700} size={17}>
          내 정보 수정
        </Text>
        <Div>
          <Text mb={4} size={12}>
            닉네임
          </Text>
          <Input
            width="100%"
            height={38}
            name="nickname"
            value={values?.nickname}
            onChange={onChange}
          />
        </Div>
        <Div>
          <Text mb={4} size={12}>
            회사 명
          </Text>
          <Select
            list={companyList}
            name="company"
            value={values?.company}
            onChange={onChange}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            width="100%"
            disabled
          />
        </Div>
      </ColDiv>
      <ColDiv width="100%" align="center" mt={20}>
        <Button
          maxWidth={315}
          width="100%"
          text="확인"
          active={active}
          onClick={submit}
        />
      </ColDiv>
    </Default>
  );
}

export default ProfilePresenter;
