import React from 'react';
import { Div } from 'styles/CommonCSS';
import Admin from 'Layout/Admin';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import styled from 'styled-components';
import BgDiv from 'components/Common/BgDiv';
import NoticeForm from 'components/Routes/Admin/NoticeForm';

function NoticeDetailPresenter({
  values,
  onChange,
  checkBoxChange,
  navigate,
  submit,
  del,
  fileUpload,
  imagePath,
}) {
  return (
    <Admin>
      <Div padding="38px 40px 0" maxWidth={1060}>
        <BgDiv
          width={32}
          ratio="1/1"
          bgColor="opacityCF"
          radius={16}
          padding="0 13px 0 10px"
          onClick={() => navigate(-1)}
          pointer
        >
          <ArrowButton direction="left" />
        </BgDiv>
        <NoticeForm
          values={values}
          onChange={onChange}
          checkBoxChange={checkBoxChange}
          navigate={navigate}
          submit={submit}
          del={del}
          fileUpload={fileUpload}
          imagePath={imagePath}
        />
      </Div>
    </Admin>
  );
}

const ArrowButton = styled(ArrowIcon)`
  cursor: pointer;
  width: 9px;
  height: 16px;
  transform: ${({ direction }) =>
    direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export default NoticeDetailPresenter;
