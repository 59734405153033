import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ColDiv, fadein, flexCol, RowDiv, scrollbar } from 'styles/CommonCSS';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import Button from 'components/Element/Button';
import Text from 'components/Element/Text';

function Agree({ isOpen, setIsOpen, title }) {
  const [currentScrollTop, setCurrentScrollTop] = useState();

  useEffect(() => {
    if (isOpen) {
      setCurrentScrollTop(window.scrollY);
      document.body.style.cssText = `
          top: -${window.scrollY}px;
          overflow-y: overlay;
          width: 100%;`;
    } else {
      document.body.style.cssText = ``;
      window.scrollTo(0, currentScrollTop);
    }

    return () => {
      document.body.style.cssText = '';
    };
  }, [isOpen]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClickModal = () => {
    if (isOpen.onClick) {
      isOpen.onClick();
    }
    setIsOpen(false);
  };

  return (
    isOpen && (
      <Wrap open={isOpen}>
        <Content>
          <RowDiv width="100%" justify="space-between" zIndex={3}>
            <Text size={24} mSize={16} color="main"></Text>
            <Close onClick={closeModal} />
          </RowDiv>
          <ColDiv width="100%">
            <Text weight={700}>{isOpen.title}</Text>
            <Text>{isOpen.text}</Text>
          </ColDiv>
          <Button
            text="OK"
            width="100%"
            bgColor="main"
            color="white"
            onClick={onClickModal}
            active
          />
        </Content>
      </Wrap>
    )
  );
}

const Wrap = styled.div`
  ${flexCol('center', 'center')}
  ${(props) =>
    props.open &&
    css`
      ${fadein()}
    `};
  z-index: 9000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  background-color: rgba(30, 30, 32, 0.46);

  @media ${({ theme }) => theme.device.mobile} {
  }
`;

const Content = styled.div`
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  padding: 20px;

  ${flexCol('center', 'space-between')};
`;

const Close = styled(CloseIcon)`
  cursor: pointer;
`;

const Col = styled(ColDiv)`
  overflow-x: hidden;
  overflow-y: overlay;
  ${scrollbar}
`;

export default Agree;
