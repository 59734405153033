import hexToRGB from 'modules/hexToRGB';
import React from 'react';
import styled from 'styled-components';
import { RowDiv } from 'styles/CommonCSS';

function GrayBackground({
  borderTop,
  borderBottom,
  direction,
  children,
  ...props
}) {
  return (
    <Wrap
      borderTop={borderTop}
      borderBottom={borderBottom}
      direction={direction}
      {...props}
    >
      {children}
    </Wrap>
  );
}

const Wrap = styled(RowDiv)`
  flex-direction: ${({ direction }) => direction || 'row'};
  background-color: ${({ theme }) => hexToRGB(theme.colors.colorCF, 0.26)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorCF};
`;

export default GrayBackground;
