// theme
import hexToRGB from 'modules/hexToRGB';

const colors = {
  main: '#007FC7',
  colorCF: '#CFCFCF',
  colorDE: '#DEDEDE',
  font: '#272F34',
  subFont: '#767676',
  warningColor: '#B70023',
  bgColorB9: '#B9B39E',
  bgColorF3: '#F38200',
  rankColor: '#4EBEFE',
  opacityCF: `${hexToRGB('#CFCFCF', 0.26)}`,
};

const element = {
  padding: '11px 10px',
  input: {
    padding: '11px 10px',
    width: '203px',
    height: '40px',
    radius: '4px',

    checkboxType: 'visibility', // disabled or visibility
  },
  button: {
    width: '108px',
    height: '38px',
    radius: '20px',
  },
};

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '450px',
  mobile: '576px',
  tablet: '768px',
};

const device = {
  mobileS: `only screen and (max-width: ${deviceSizes.mobileS})`,
  mobileM: `only screen and (max-width: ${deviceSizes.mobileM})`,
  mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
  mobile: `only screen and (max-width: ${deviceSizes.mobile})`,
  tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
  ratio: `only screen and (max-width: 1280px) and (min-aspect-ratio: 16/9),
  screen and (max-width: 1900px) and (min-aspect-ratio: 2/1)`,
};

const theme = {
  deviceSizes,
  colors,
  device,
  element,
};

export default theme;
