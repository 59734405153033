import React, { useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { ColDiv, Div } from 'styles/CommonCSS';
import hexToRGB from 'modules/hexToRGB';
import Text from './Text';

function Toast({ toastText, setToastText, children, ...props }) {
  useEffect(() => {
    if (toastText) {
      setTimeout(() => {
        setToastText();
      }, [2000]);
    }
  }, [toastText]);
  return (
    <ToastMsg align="center" justify="center" {...props}>
      <Text size={12} mLineHeight="20px" textAlign="center">
        {toastText}
      </Text>
    </ToastMsg>
  );
}

const ToastMsg = styled(ColDiv)`
  z-index: 1000;
  position: absolute;
  background-color: white;
  bottom: 60px;
  padding: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.opacityCF}`};
  text-align: center;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  box-shadow: 0px 4px 10px ${({ theme }) => hexToRGB(theme.colors.font, 0.06)};
`;

export default Toast;
