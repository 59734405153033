/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Div } from 'styles/CommonCSS';
import './viewer.css';

function Viewer({ value }) {
  return value ? (
    <Div className="ck-content" dangerouslySetInnerHTML={{ __html: value }} />
  ) : (
    <></>
  );
}

export default Viewer;
