import Text from 'components/Element/Text';
import React from 'react';
import styled from 'styled-components';
import { flexRow, RowDiv, space, common } from 'styles/CommonCSS';
import { input, inputSize } from 'styles/InputCSS';
import { ReactComponent as AttachIcon } from 'assets/icon/input/attach.svg';
import Loading from 'components/Element/Loading';

function InputFile({
  invalid,
  value,
  setValues,
  name,
  fileUpload,
  placeholder,
  onChange,
  readOnly,
  accept,
  remove,
  type,
  text,
  ...props
}) {
  const onChangeHandler = (event) => {
    event.preventDefault();
    fileUpload(event);
    // const file = event.target.files[0];
    // if (file) {
    //   const fd = new FormData();
    //   fd.append('file', file);
    //   fileUpload(file);
    // }
  };

  return (
    <Wrap htmlFor={name} invalid={invalid} {...props}>
      <input
        id={name}
        type="file"
        accept={accept}
        readOnly
        name={name}
        onChange={onChangeHandler}
        style={{ display: 'none' }}
      />
      <RowDiv
        width="100%"
        align="center"
        gap={10}
        justify={type === 'user' && 'center'}
      >
        {type === 'Attach' && (
          <AttachIcon width={12} style={{ minWidth: '12px' }} />
        )}
        <Text
          color={type === 'user' ? 'white' : value ? 'fontBlack' : 'lightGray'}
          weight={type === 'user' ? '700' : '400'}
          size={(type === 'user' && '12px') || '14px'}
        >
          {type === 'user' ? text : value || placeholder}
        </Text>
      </RowDiv>
    </Wrap>
  );
}

const Wrap = styled.label`
  position: relative;
  ${input}
  ${inputSize}
  ${space}
${common}
  ${flexRow('center', 'space-between')}
  gap: 10px;
  overflow: hidden;
  cursor: pointer;
`;

export default InputFile;
