import React from 'react';
import Text from 'components/Element/Text';
import styled from 'styled-components';
import { Div, scrollbar } from 'styles/CommonCSS';
import BasicModal from 'components/Element/Modal/BasicModal';

// function CompanyDetail({ modalOpen, setModalOpen }) {
//   return (
//     <BasicModal modalOpen={modalOpen} setModalOpen={setModalOpen} type="user">
//       <Div padding="0 14px" mt={20}>
//         <Img src={modalOpen.image} width="100%" />
//       </Div>
//       <Text size={14} weight={700} mt={10}>
//         {modalOpen.name}
//       </Text>
//       <ScrollDiv maxHeight={100} mb={20}>
//         <Text color="subFont" margin="10px 0 20px" minHeight={100}>
//           {modalOpen.description}
//         </Text>
//       </ScrollDiv>
//     </BasicModal>
//   );
// }

function CompanyDetail({ modalOpen, setModalOpen }) {
  return (
    <BasicModal modalOpen={modalOpen} setModalOpen={setModalOpen} type="user">
      <ScrollDiv maxHeight={400} padding="0 14px" mt={20} mb={20}>
        <Img src={modalOpen.image} width="100%" />
      </ScrollDiv>
    </BasicModal>
  );
}
const Img = styled.img`
  width: 100%;
`;

const ScrollDiv = styled(Div)`
  overflow-y: auto;
  ${scrollbar}
`;
export default CompanyDetail;
