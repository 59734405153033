/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Div } from 'styles/CommonCSS';
import './Firecracker.css';

function Firecracker(props) {
  const particles = [];

  const colors = ['#485FFA', '#FC7FA1', '#FFE48B', '#6BD6E2'];

  const pop = () => {
    for (let i = 0; i < 150; i++) {
      const p = document.createElement('particule');
      p.x = window.innerWidth * 0.5;
      p.y = window.innerHeight + Math.random() * window.innerHeight * 0.3;
      p.vel = {
        x: (Math.random() - 0.5) * 10,
        y: Math.random() * -20 - 15,
      };
      p.mass = Math.random() * 0.2 + 0.8;
      particles.push(p);
      p.style.transform = `translate(${p.x}px, ${p.y}px)`;
      const size = Math.random() * 15 + 5;
      p.style.width = `${size}px`;
      p.style.height = `${size}px`;
      p.style.background = colors[Math.floor(Math.random() * colors.length)];
      document.getElementById('animationDocument').appendChild(p);
    }

    setTimeout(() => {
      render();
    }, [400]);
  };

  function render() {
    for (let i = particles.length - 1; i--; i > -1) {
      const p = particles[i];
      p.style.transform = `translate3d(${p.x}px, ${p.y}px, 1px)`;

      p.x += p.vel.x;
      p.y += p.vel.y;

      p.vel.y += 0.5 * p.mass;
      if (p.y > window.innerHeight * 2) {
        p.remove();
        particles.splice(i, 1);
      }
    }
    requestAnimationFrame(render);
  }

  useEffect(() => {
    pop();
  }, []);

  return <Wrap id="animationDocument" onClick={pop} />;
}

const Wrap = styled(Div)`
  z-index: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
`;

export default Firecracker;
