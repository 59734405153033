import React from 'react';
import { RowDiv, ColDiv, nonScrollbar, Div } from 'styles/CommonCSS';
import Text from 'components/Element/Text';
import Admin from 'Layout/Admin';
import Select from 'components/Input/Select';
import Button from 'components/Element/Button';
import useSelectOpen from 'hooks/useSelectOpen';
import OpacityDiv from 'components/Common/OpacityDiv';
import BgDiv from 'components/Common/BgDiv';
import Input from 'components/Input/Input';
import styled from 'styled-components';

function ChallengeListPresenter({
  list,
  monthList,
  setModalOpen,
  del,
  filter,
  filterOnChange,
  goDetail,
  excelDownload,
}) {
  const { isOpen, setIsOpen } = useSelectOpen();

  return (
    <Admin>
      <Div padding="85px 120px 0" maxWidth={1060}>
        <Text weight={700} size={20}>
          챌린지 관리
        </Text>
        <RowDiv mt={10} mb={20} justify="space-between">
          <RowDiv gap={20}>
            <Input
              value={filter.year}
              name="year"
              onChange={filterOnChange}
              bgColor="white"
            />
            <Select
              list={monthList}
              name="month"
              value={filter.month}
              onChange={filterOnChange}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              type="admin"
            />
          </RowDiv>
          <RowDiv gap={6}>
            <BgDiv
              pointer
              border="main"
              radius={20}
              width={120}
              height={40}
              onClick={excelDownload}
            >
              <Text color="main" weight={700} size={14}>
                엑셀 다운로드
              </Text>
            </BgDiv>
            <Button
              text="추가"
              width={86}
              height={40}
              onClick={() => setModalOpen('add')}
              active
            />
          </RowDiv>
        </RowDiv>
        <ScrollDiv>
          {list?.map((item) => (
            <OpacityDiv
              opBorder="colorCF"
              bgColor="white"
              radius={6}
              key={item.id}
              mt={10}
            >
              <RowDiv padding="30px 20px" justify="space-between" width="100%">
                <Text weight={700} size={14}>
                  {item.title}
                </Text>
                <RowDiv gap={6}>
                  {item.type === 'image' && (
                    <BgDiv
                      pointer
                      bgColor="main"
                      radius={20}
                      width={108}
                      height={40}
                      onClick={() => goDetail(item)}
                    >
                      <Text color="white" size={14}>
                        심사
                      </Text>
                    </BgDiv>
                  )}
                  <BgDiv
                    pointer
                    border="main"
                    radius={20}
                    width={108}
                    height={40}
                    onClick={() => setModalOpen(item)}
                  >
                    <Text color="main" size={14}>
                      수정
                    </Text>
                  </BgDiv>
                  <BgDiv
                    pointer
                    bgColor="colorCF"
                    radius={20}
                    width={108}
                    height={40}
                    onClick={() => del(item.id)}
                  >
                    <Text color="white" size={14}>
                      삭제
                    </Text>
                  </BgDiv>
                </RowDiv>
              </RowDiv>
            </OpacityDiv>
          ))}
        </ScrollDiv>
      </Div>
    </Admin>
  );
}

const ScrollDiv = styled(ColDiv)`
  ${nonScrollbar};
  height: calc(100vh - 210px);
  overflow-y: overlay;
`;

export default ChallengeListPresenter;
