import React from 'react';
import styled from 'styled-components';
import { ColDiv, Div } from 'styles/CommonCSS';

function NoHeader({ children }) {
  return (
    <Div>
      <Content
        align="center"
        padding="46px 30px 40px 30px"
        minHeight="100vh"
        maxWidth={768}
        margin="0 auto"
      >
        {children}
      </Content>
    </Div>
  );
}
const Content = styled(ColDiv)`
  margin: 0 auto;
  position: relative;
`;
export default NoHeader;
