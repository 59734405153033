import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ColDiv, flexRow, RowDiv } from 'styles/CommonCSS';
import { ReactComponent as LogoIcon } from 'assets/icon/header-logo.svg';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import { ReactComponent as MenuIcon } from 'assets/icon/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import hexToRGB from 'modules/hexToRGB';
import routes from 'data/routes';
import Text from 'components/Element/Text';
import { useCookies } from 'react-cookie';

function MobileHeader(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [cookies, setCookies, removeCookie] = useCookies(['token', 'nickname']);

  const removeToken = () => {
    console.log('remove');
    removeCookie('token', { path: '/' });
    removeCookie('nickname', { path: '/' });
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <Wrap>
      <RowDiv width="100%" justify="space-between" align="center">
        <Logo onClick={() => (window.location.href = '/')} />
        <Menu
          open={open}
          onClick={() => {
            setOpen(true);
          }}
        >
          <MenuIcon />
        </Menu>
      </RowDiv>
      <WrapBackground
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      />
      <Content open={open}>
        <RowDiv justify="flex-end" mb={24}>
          <Close
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Close>
        </RowDiv>
        <Row
          padding="0 30px"
          gap={10}
          align="center"
          justify="end"
          mb={40}
          pointer
          onClick={removeToken}
        >
          <Text color="subFont">로그아웃</Text>
          <ArrowIcon />
        </Row>
        <ColDiv padding="0 20px" gap={10}>
          {routes.map((route, index) => (
            <SubNav
              key={`route${index}`}
              padding="10px 20px 10px 10px"
              height="100%"
              pointer
              onClick={() => navigate(route.pathname)}
              selected={location.pathname === route.pathname}
            >
              <TextP
                selected={location.pathname === route.pathname}
                size={14}
                weight={location.pathname === route.pathname ? '700' : '400'}
              >
                {route.label}
              </TextP>
              <ArrowIcon />
            </SubNav>
          ))}
        </ColDiv>
      </Content>
    </Wrap>
  );
}

const Wrap = styled.header`
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  z-index: 900;
  transition: all 150ms ease;
  background-color: white;
  max-width: 768px;
`;

const Menu = styled.button`
  padding: 15px 20px;
  ${flexRow('center', 'center')}
  opacity: ${({ open }) => (open ? '0' : '1')};
  transition-delay: 0.3s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  & > svg > path {
    fill: ${({ theme }) => theme.colors.main};
  }
`;

const Close = styled.button`
  padding: 20px 30px 16px;
  & > svg > path {
    fill: ${({ theme }) => theme.colors.subFont};
  }
  ${flexRow('center', 'center')}
`;

const WrapBackground = styled.div`
  z-index: 0;
  position: fixed;
  top: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  max-width: 768px;
  width: 100%;
  height: 100vh;
  background-color: rgba(30, 30, 32, 0.46);
`;

const Content = styled(ColDiv)`
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? '0px' : '-85%')};
  width: 85%;
  max-width: 320px;
  height: 100vh;
  background-color: white;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 2px 0px 10px ${({ theme }) => hexToRGB(theme.colors.font, 0.01)};
`;

const SubNav = styled(RowDiv)`
  ${flexRow('center', 'space-between')}
  height: 40px;
  cursor: pointer;

  & > svg > path {
    fill: ${({ selected, theme }) =>
      selected ? theme.colors.main : theme.colors.subFont};
  }
`;

const TextP = styled(Text)`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.main : theme.colors.mainFont};
`;

const Row = styled(RowDiv)`
  & > svg > path {
    fill: ${({ theme }) => theme.colors.subFont};
  }
`;

const Logo = styled(LogoIcon)`
  cursor: pointer;
  padding: 7px 20px;
`;

export default MobileHeader;
