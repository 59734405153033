import React, { useState, useEffect } from 'react';
import Alert from 'components/Element/Modal/Alert';
import { codeApi } from 'api';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'react-use';
import { useCookies } from 'react-cookie';
import Loading from 'components/Element/Loading';
import MainPresenter from './LoginPresenter';

function LoginContainer(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'nickname']);
  const [phoneActive, setPhoneActive] = useState(false);
  const [codeActive, setCodeActive] = useState(false);
  const [invalids, setInvalids] = useState({
    phone: { invalid: false, text: '' },
    code: { invalid: false, text: '' },
  });
  const [values, setValues] = useState({
    phone: '',
    code: '',
    agree1: false,
    agree2: false,
  });
  const [active, setActive] = useState(false);
  const [warning, setWarning] = useState();

  const onChange = (value, name) => {
    if (name === 'phone') {
      initRunning();
      setValues({ ...values, phone: value, code: '' });
    } else {
      setValues({ ...values, [name]: value });
    }
    invalidCheck(value, name);
  };
  const checkBoxChange = (event) => {
    const { checked, name } = event.target;
    setValues({ ...values, [name]: checked });
  };

  const invalidCheck = (value, name) => {
    const reg = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/;
    const reg1 = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

    if (value) {
      if (name === 'phone') {
        if (!reg.test(value) && !reg1.test(value)) {
          if (value.length === 1) {
            setInvalids({
              ...invalids,
              [name]: {
                invalid: false,
                text: '',
              },
            });
          } else {
            setInvalids({
              ...invalids,
              [name]: {
                invalid: true,
                text: '휴대폰번호를 확인해주세요.',
              },
            });
          }
        } else {
          setInvalids({
            ...invalids,
            [name]: {
              invalid: false,
              text: '',
            },
          });
        }
      }
      if (name === 'code') {
        if (value.length !== 6) {
          setInvalids({
            ...invalids,
            [name]: {
              invalid: true,
              text: '6자리 인증번호를 입력해주세요.',
            },
          });
        } else {
          setInvalids({
            ...invalids,
            [name]: {
              invalid: false,
              text: '',
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    setPhoneActive(values.phone && invalids.phone.invalid !== true);
    setCodeActive(
      values.phone &&
        invalids.phone.invalid === 'success' &&
        values.code &&
        invalids.code.invalid !== true,
    );
  }, [values, invalids]);

  const delay = 1000;
  const [isRunning, setIsRunning] = useState(false);
  const [minutes, setMinutes] = useState(parseInt(3, 10));
  const [seconds, setSeconds] = useState(parseInt(0, 10));

  const initRunning = () => {
    setIsRunning(false);
    setMinutes(3);
    setSeconds(0);
  };

  const [loading, setLoading] = useState(false);
  const sendCode = async () => {
    let tempInvalids = { ...invalids };
    if (values.phone) {
      if (phoneActive && (!isRunning || minutes < 2)) {
        setValues({ ...values, code: '' });
        setIsRunning(true);
        setLoading(true);
        const [info] = await codeApi.send(values.phone.replace(/-/g, ''));
        if (info) {
          tempInvalids = {
            ...tempInvalids,
            phone: {
              invalid: 'success',
              text: '3분 이내로 인증번호를 입력하세요.',
            },
          };
          setIsRunning(true);
          setLoading(false);
        } else {
          tempInvalids = {
            ...tempInvalids,
            phone: {
              invalid: true,
              text: '오류가 발생 했습니다. 다시 시도해 주세요.',
            },
          };
          setLoading(false);
        }
      } else if (isRunning && minutes > 1) {
        tempInvalids = {
          ...tempInvalids,
          phone: {
            invalid: 'success',
            text: '인증 문자는 1분 뒤 재발송 가능 합니다.',
          },
        };
      }
    } else if (values.agree1 === false || values.agree2 === false) {
      tempInvalids = {
        ...tempInvalids,
        phone: { invalid: true, text: '약관에 동의해주세요.' },
      };
    } else {
      tempInvalids = {
        ...tempInvalids,
        phone: { invalid: true, text: '휴대폰 번호를 입력해주세요.' },
      };
    }
    setInvalids({ ...tempInvalids });
  };

  useInterval(
    () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setIsRunning(false);
          setValues({ ...values, code: '' });
          setInvalids({
            ...invalids,
            phone: { invalid: false, text: ' ' },
            code: { invalid: false, text: '' },
          });
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    },
    isRunning ? delay : null,
  );

  const checkCode = async () => {
    if (codeActive) {
      setLoading(true);

      const [info] = await codeApi.code(
        values.phone.replace(/-/g, ''),
        values.code,
      );
      if (info) {
        setInvalids({
          ...invalids,
          phone: {
            invalid: 'success',
            text: '',
          },
          code: {
            invalid: 'success',
            text: '인증이 완료되었습니다.',
          },
        });
        setIsRunning(false);
        setLoading(false);
        sessionStorage.setItem(
          'phone',
          values.phone.replace(/0/, 82).replace(/(\d{2})(\d{9,10})/, '$1 $2'),
        );
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        setCookie('token', info.result.token, { path: '/', expires });
        setCookie('nickname', info.result.nickname, { path: '/', expires });
        if (info.result.isSignUp === 0) {
          navigate('/signup');
        } else if (info.result.isSignUp === 1) {
          navigate('/');
          sessionStorage.removeItem('phone');
        }
      } else {
        setInvalids({
          ...invalids,
          code: {
            invalid: true,
            text: '인증번호가 일치하지 않습니다.',
          },
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (values.agree1 === false || values.agree2 === false) {
      setValues({ ...values, phone: '' });
      setInvalids({ ...invalids, phone: { invalid: false, text: '' } });
    }
  }, [values.agree1, values.agree2]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Alert modal={warning} setModal={setWarning} />
      <MainPresenter
        onChange={onChange}
        values={values}
        active={active}
        checkBoxChange={checkBoxChange}
        phoneActive={phoneActive}
        codeActive={codeActive}
        isRunning={isRunning}
        minutes={minutes}
        seconds={seconds}
        sendCode={sendCode}
        checkCode={checkCode}
        invalids={invalids}
        setInvalids={setInvalids}
      />
    </>
  );
}

export default LoginContainer;
